import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { ShouldRender } from '@cdd/ui-kit/lib/components/conditionals/ShouldRender/v1';
import {
  BioisosterFragment,
  BioisosterSettings,
  ReferenceStructure,
  DLBioisosterRequest,
  Bioisoster,
} from './BioisosterResultsTypes';
import {
  BioisosterInfoButton,
  BioisosterLaunchVisualizationButton,
  BioisosterSettingsButton,
  BioisostersDownloadButton,
} from './BioisosterResultsControls';
import { BioisosterResults } from './BioisosterResults';
import { PropertyCalculator } from '../Properties/calculator';
import { usePersistentState } from '../persistence';
import { BioisosterInfoNoFragments } from './BioisosterInfoDialog';
import { FragmentSelector } from './FragmentSelector';
import { CenterContent, delay, OverlayMessageSpinner } from '../layout';
import { describeSortOrder, noSort } from '../Properties/propertySorting';
import DOMPurify from 'dompurify';

const hasExperimentalFeatures = false;

type BioisosterResultsDialogProps = {
  open: boolean;
  fetchDLBioisoster: DLBioisosterRequest;
  smiles: string;
  onClose?: () => void;
  banExternalLinks: boolean;
  skipExternalLinkWarning: boolean;
  canRegisterMolecules: boolean;
  interactiveFragmentSelector?: boolean;
};

export const BioisosterResultsDialog = (props: BioisosterResultsDialogProps) => {
  const { open, smiles, fetchDLBioisoster } = props;
  let { interactiveFragmentSelector = false } = props;
  const [bioisosterFragments, setBioisosterFragments] = useState<
    Array<BioisosterFragment>
  >([]);
  const [fragments, setFragments] = useState<Array<string>>();
  const [reference, setReference] = useState<ReferenceStructure>({
    structure: '',
    properties: {},
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [settings, setSettings] = usePersistentState<BioisosterSettings>(
    'deep-learning-bioisosteric-suggestions',
    {
      properties: [],
      count: 12,
      showCollectionInformation: false,
      experimental: false,
      sortBy: noSort,
    },
  );
  const [calculator] = useState(new PropertyCalculator());

  // clear experimental settings if experimental features are not available
  if (settings.experimental && !hasExperimentalFeatures) {
    setSettings({ ...settings, experimental: false });
  }

  // pick fragment selector based on molecule size and user settings
  const largeMolecule = smiles.split(' ')[0].replace(/[Ha-z@()[\]0-9=]/g, '').length > 50;
  interactiveFragmentSelector = largeMolecule || interactiveFragmentSelector || settings.experimental;

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      props.onClose?.();
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setLoading(true);
    setMessage('Retrieving results');
    fetchDLBioisoster(smiles, settings.count, { fragmentation: interactiveFragmentSelector }).then(async (response) => {
      if ('error' in response || response.status !== 'OK' || !response.result) {
        setMessage('');
        setLoading(false);
        setLoadingFailed(true);
        return;
      }
      setMessage('Calculating properties');
      await delay(1);
      await calculator.is_ready();
      setReference({
        structure: response.result.structure,
        properties: calculator.predict(response.result.structure),
      });
      response.result.suggestions.forEach((suggestion) => {
        suggestion.bioisosters.forEach((bioisoster) => {
          bioisoster.properties = calculator.predict(bioisoster.structure);
        });
        sortResults(suggestion.bioisosters, settings);
      });
      setBioisosterFragments(response.result.suggestions);
      setFragments(interactiveFragmentSelector ? [] : response.result.suggestions.map((suggestion) => suggestion.fragment));
      setMessage('');
      setLoading(false);
    });
  }, [smiles, open, settings.count]);

  const expandSuggestions = async (fragment: BioisosterFragment, count = 200) => {
    setMessage('Retrieving results');
    const response = await fetchDLBioisoster(smiles, count, { filter_fragments: fragment.atomIdx });
    if (!('result' in response)) {
      setMessage('');
      return;
    }
    setMessage('Calculating properties');
    await delay(1);
    await calculator.is_ready();
    response.result.suggestions.forEach((suggestion) => {
      const bioisosterFragment = bioisosterFragments.find((bf) => bf.fragment === suggestion.fragment);
      if (bioisosterFragment.isExpanded || bioisosterFragment.bioisosters.length >= count) {
        return;
      }

      suggestion.bioisosters.forEach((bioisoster) => {
        bioisoster.properties = calculator.predict(bioisoster.structure);
      });

      bioisosterFragment.bioisosters = suggestion.bioisosters;
      sortResults(bioisosterFragment.bioisosters, settings);
      bioisosterFragment.isExpanded = count == 200;
    });
    setBioisosterFragments([...bioisosterFragments]);
    setMessage('');
  };

  const handleFragmentSelectionChange = async (fragments: Array<string>) => {
    const requireRetrieval = fragments.some(fragment => {
      const match = bioisosterFragments.find(bf => bf.fragment === fragment);
      return match?.bioisosters.length === 0;
    });

    if (!requireRetrieval) {
      setFragments(fragments);
      return;
    }

    const smallest = bioisosterFragments
      .filter((fragment) => fragments.includes(fragment.fragment))
      .reduce((prev, curr) => (curr.atomIdx.length < prev.atomIdx.length ? curr : prev));
    await expandSuggestions(smallest, settings.count);
    setFragments(fragments);
  };

  const changeSettings = (newSettings: BioisosterSettings) => {
    if (settings.sortBy != newSettings.sortBy) {
      bioisosterFragments.forEach((fragment) => {
        sortResults(fragment.bioisosters, newSettings);
      });
    }
    setSettings(newSettings);
  };

  return (
    <Dialog
      className='BioisosterResultsDialog'
      open={open}
      fullWidth
      maxWidth='xl'
      PaperProps={{ sx: { height: 'calc(100% - 64px)' } }}
      onKeyDown={handleKeyDown}
    >
      <BioisosterResultsDialogTitle
        loading={loading}
        bioisosterFragments={bioisosterFragments}
        reference={reference}
        settings={settings}
        onChangeSettings={changeSettings}
      />
      <DialogContent>
        <BioisosterContent
          bioisosterFragments={bioisosterFragments}
          fragments={fragments}
          reference={reference}
          settings={settings}
          banExternalLinks={props.banExternalLinks}
          canRegisterMolecules={props.canRegisterMolecules}
          loading={loading}
          loadingFailed={loadingFailed}
          onExpandSuggestions={expandSuggestions}
          interactiveFragmentSelector={interactiveFragmentSelector}
          onFragmentSelectionChange={handleFragmentSelectionChange} />
        <ShouldRender shouldRender={message !== ''}>
          <OverlayMessageSpinner message={message} />
        </ShouldRender>
      </DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <Stack
          direction='row'
          justifyContent='flex-start'
          spacing={1}
          divider={<Divider orientation='vertical' flexItem />}
          sx={{ padding: '10px' }}
        >
          <BioisosterFooter
            skipExternalLinkWarning={props.skipExternalLinkWarning}
            banExternalLinks={props.banExternalLinks}
          />
        </Stack>
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={props.onClose}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const BioisosterContent = (props: {
  bioisosterFragments: Array<BioisosterFragment>;
  fragments: Array<string>;
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
  loading: boolean;
  loadingFailed: boolean;
  interactiveFragmentSelector: boolean;
  onExpandSuggestions: (fragment: BioisosterFragment) => void;
  onFragmentSelectionChange: (fragments: Array<string>) => void;
}) => {
  const {
    bioisosterFragments, fragments, reference, settings, loading, loadingFailed,
    onFragmentSelectionChange, interactiveFragmentSelector,
  } = props;
  if (loadingFailed) {
    return renderErrorFallback();
  }
  if (loading) {
    return CenterContent(<div>Loading...</div>);
  }
  if (!bioisosterFragments || bioisosterFragments.length == 0) {
    return <BioisosterInfoNoFragments />;
  }
  return (
    <>
      <FragmentSelector
        fragments={fragments}
        structure={reference.structure}
        onChange={onFragmentSelectionChange}
        bioisosterFragments={bioisosterFragments}
        interactive={interactiveFragmentSelector}
        additionalInformation={describeSortOrder(settings.sortBy)} />
      <ShouldRender shouldRender={fragments.length > 0}>
        <BioisosterResults
          reference={reference}
          bioisosterFragments={bioisosterFragments.filter((bioisosterFragment) => fragments.includes(bioisosterFragment.fragment))}
          settings={settings}
          banExternalLinks={props.banExternalLinks}
          canRegisterMolecules={props.canRegisterMolecules}
          onExpandSuggestions={props.onExpandSuggestions}
        />
      </ShouldRender>
      <ShouldRender shouldRender={interactiveFragmentSelector && fragments.length == 0}>
        <RequireFragmentSelection />
      </ShouldRender>
    </>
  );
};

const RequireFragmentSelection = () => {
  return (
    <Box display='flex' height='50%'>
      <Box m='auto' paddingLeft='200px' paddingRight='200px'>
        <div>
          Please select a fragment by clicking on the structure to view
          the bioisosteric suggestions.
        </div>
      </Box>
    </Box>
  );
};

const BioisosterFooter = (props: {
  skipExternalLinkWarning: boolean;
  banExternalLinks: boolean;
}) => {
  const { skipExternalLinkWarning, banExternalLinks } = props;
  const common = { fontWeight: 'light', color: 'grey' };
  const footer = [];
  if (!skipExternalLinkWarning && !banExternalLinks) {
    footer.push(
      <Box style={common} key='external'>
        Warning: links open an external website
      </Box>,
    );
  }
  return <Stack
    direction='row'
    justifyContent='flex-start'
    spacing={1}
    divider={<Divider orientation='vertical' flexItem />}
    sx={{ padding: '10px' }}
  >
    {footer}
  </Stack>;
};

type BioisosterResultsDialogTitleProps = {
  loading: boolean;
  bioisosterFragments: Array<BioisosterFragment>;
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  onChangeSettings: (settings: BioisosterSettings) => void;
};

const BioisosterResultsDialogTitle = (
  props: BioisosterResultsDialogTitleProps,
) => {
  const {
    loading,
    bioisosterFragments,
    reference,
    settings,
    onChangeSettings,
  } = props;
  const disabled = loading || !bioisosterFragments.length;
  return (
    <DialogTitle className='muiDialog-title'>
      <Box display='flex' justifyContent='space-between'>
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <span>{'Bioisosteric suggestions'}</span>
          </Stack>
          <Stack direction='row' spacing={4}>
            <BioisosterLaunchVisualizationButton
              disabled={disabled}
              reference={reference}
              bioisosterFragments={bioisosterFragments}
              displayProperties={settings.properties}
              bioisosterSettings={settings}
            />
            <BioisostersDownloadButton
              disabled={disabled}
              reference={reference}
              bioisosterFragments={bioisosterFragments}
              displayProperties={settings.properties}
              bioisosterSettings={settings}
            />
            <BioisosterSettingsButton
              settings={settings}
              disabled={disabled}
              onChange={onChangeSettings}
              hasExperimentalFeatures={hasExperimentalFeatures}
            />
          </Stack>
        </Stack>
        <BioisosterInfoButton />
      </Box>
    </DialogTitle>
  );
};

const renderErrorFallback = () => {
  let message = 'Cannot retrieve bioisostere suggestions.';
  if (window.location.href.includes('localhost')) {
    message +=
      ' Start the deep learning similarity server on your development machine' +
      ' using &quot;make dlsimilarity&quot; and stop it using &quot;make dlsimilarity_stop&quot;';
  }
  return (
    <Box display='flex' height='100%'>
      <Box
        className='error-boundary-msg'
        m='auto'
        paddingLeft='200px'
        paddingRight='200px'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
      />
    </Box>
  );
};

const sortResults = (bioisosters: Array<Bioisoster>, settings: BioisosterSettings) => {
  if (settings.sortBy && settings.sortBy.order !== 'none') {
    bioisosters.sort((a, b) => {
      const aProp = a.properties[settings.sortBy.sortKey];
      const bProp = b.properties[settings.sortBy.sortKey];
      if (aProp === undefined || bProp === undefined) {
        return 0;
      }
      return settings.sortBy.order === 'asc' ? aProp - bProp : bProp - aProp;
    });
    return;
  }
  // fallback to sort by tanimoto similarity descending
  bioisosters.sort((a, b) => b.tanimoto - a.tanimoto);
};
