import { Location2D, Size2D } from '@/types';
import { DrawableElement } from '../types';
import { FieldDataType } from '@/FieldDefinitions/types';
import { UnitSystem } from '@/stores/localPersistenceStore';

export type NumberPercentZeroToOne = number & { __type: 'percentZeroToOne' };

// Can add support for QR Code if needed.
// https://github.com/soldair/node-qrcode
// Might be worth looking into using this library
// https://bwip-js.metafloor.com/
export type FieldSchema = Location2D<NumberPercentZeroToOne> &
  Size2D<NumberPercentZeroToOne> & {
    supportedDataTypes: FieldDataType[];
  };

// Here we just list the barcodes that JSBarcode supports, not all are necessarily implemented here until there is evidence of a need.
// https://github.com/lindell/JsBarcode?tab=readme-ov-file#supported-barcodes
export enum JSBarcodeSymbology {
  CODE128 = 'CODE128',
  CODE128A = 'CODE128A',
  CODE128B = 'CODE128B',
  CODE128C = 'CODE128C',
  //
  EAN13 = 'EAN13',
  UPC = 'UPC',
  EAN8 = 'EAN8',
  EAN5 = 'EAN5',
  EAN2 = 'EAN2',
  //
  CODE39 = 'CODE39',
  //
  ITF14 = 'ITF14',
  //
  MSI10 = 'MSI10',
  MSI11 = 'MSI11',
  MSI1010 = 'MSI1010',
  MSI1110 = 'MSI1110',
  //
  pharmacode = 'pharmacode',
  //
  Codabar = 'Codabar',
}
export enum QRCodeSymbology {
  QRCode = 'QRCode',
}
export const SymbologySupportedTypes: Record<
  JSBarcodeSymbology | QRCodeSymbology,
  FieldDataType[]
> = {
  // EAN-13 is sometimes printed with with a character after the barcode, most common is the > character. This is supported in JsBarcode with the lastChar option.
  // Must be certain number of digits to be valid depending on the
  [JSBarcodeSymbology.EAN13]: [FieldDataType.Number],
  [JSBarcodeSymbology.UPC]: [FieldDataType.Number],
  [JSBarcodeSymbology.EAN8]: [FieldDataType.Number],
  [JSBarcodeSymbology.EAN5]: [FieldDataType.Number],
  [JSBarcodeSymbology.EAN2]: [FieldDataType.Number],
  // CODE128 has support for all 128 ASCII characters but does also encode numbers efficiently.
  [JSBarcodeSymbology.CODE128]: [FieldDataType.Text, FieldDataType.Number],
  [JSBarcodeSymbology.CODE128A]: [FieldDataType.Text, FieldDataType.Number],
  [JSBarcodeSymbology.CODE128B]: [FieldDataType.Text, FieldDataType.Number],
  [JSBarcodeSymbology.CODE128C]: [FieldDataType.Text, FieldDataType.Number],
  [JSBarcodeSymbology.CODE39]: [FieldDataType.Number],
  // The ITF-14 will always encode 14 digits.
  [JSBarcodeSymbology.ITF14]: [FieldDataType.Number],
  // MSI supports digits 0-9
  [JSBarcodeSymbology.MSI10]: [FieldDataType.Number],
  [JSBarcodeSymbology.MSI11]: [FieldDataType.Number],
  [JSBarcodeSymbology.MSI1010]: [FieldDataType.Number],
  [JSBarcodeSymbology.MSI1110]: [FieldDataType.Number],
  // pharmacode only supports 3 <= x <= 131070 - TODO add support for this if desired
  [JSBarcodeSymbology.pharmacode]: [FieldDataType.Number],
  // Codabar is an old barcode type that can encode numbers and a number of special characters (–, $, :, /, +, .).
  // You can set start and stop characters to A, B, C or D but if no start and stop character is defined A will be used.
  [JSBarcodeSymbology.Codabar]: [FieldDataType.Number],
  //
  [QRCodeSymbology.QRCode]: [FieldDataType.Text, FieldDataType.Number],
};
export type BarcodeFieldSchema = FieldSchema & {
  symbology: JSBarcodeSymbology | QRCodeSymbology;
};

export type GroupOptions = {
  spacing?: number;
  direction?: string;
};

export interface ILabelDrawable {
  draw(document: any, value: any, field_schema: FieldSchema): void;
  drawArray(
    document: any,
    values: any[],
    field_schema: FieldSchema,
    group_options: GroupOptions,
  ): void;
}
export type DocumentProps = {
  width: number;
  height: number;
  unit: UnitSystem;
};
export type LabelFieldDefinition<
  TDrawableElement extends DrawableElement = DrawableElement,
> = {
  fieldSchema: TDrawableElement extends DrawableElement.Barcode
    ? BarcodeFieldSchema
    : FieldSchema;
  drawableType: TDrawableElement;
  fieldValuesUnique: boolean;
  name: string;
};

export type LabelElementMapTemplate = Record<
  string,
  Omit<LabelFieldDefinition<DrawableElement>, 'documentProps' | 'fieldKey'>
>;
