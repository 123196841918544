import { documentSize } from '@/Labels/Computation/drawableUtils';
import { Typography, CircularProgress, Button } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { LabelRollDrawer } from '@/Labels/Computation/Label';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
import { TEMPLATES } from '@/Labels/Computation/label_templates';
import { BarcodeSubmitProps } from './BarcodeSettings';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { ProcessedInventoryEntry } from './components/types';
import './InventoryLabelLoaderView.sass';
import { Size2D } from '@/types';
import axios from 'axios';
import { UnitSystem } from '@/stores/localPersistenceStore';
import { InventoryStoreHelper } from './stores/InventoryStoreHelper';

type PreviewProps = {
  samples: ProcessedInventoryEntry[];
  barcodeSpecification: BarcodeSubmitProps | null;
  fieldDefinitions: FieldDefinition[];
  all_samples_count: number;
  all_samples_url: string;
  setCalculatedNumSamples: (numSamples: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export function PreviewLabel({
  barcodeSpecification,
  samples,
  fieldDefinitions,
  all_samples_count,
  all_samples_url,
  setCalculatedNumSamples,
  loading,
  setLoading,
}: PreviewProps) {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (barcodeSpecification) {
      setLoading(true);
      const {
        svgs: [PreviewSVG],
      } = generateSVGs(barcodeSpecification, [samples[0]], fieldDefinitions);
      if (svgRef.current) {
        // eslint-disable-next-line no-unsafe-innerhtml/no-unsafe-innerhtml
        svgRef.current.innerHTML = PreviewSVG.innerHTML;
      }
      setLoading(false);
    }
  }, [barcodeSpecification, samples]);

  const generateLabels = async () => {
    if (barcodeSpecification) {
      setLoading(true);
      try {
        const { width, height, svgs } = await runGenerateSamplesForPage(
          barcodeSpecification,
          fieldDefinitions,
          all_samples_url,
          setCalculatedNumSamples,
        );
        await generatePDF(width, height, svgs);
        setCalculatedNumSamples(0);
      } finally {
        setLoading(false);
      }
    }
  };

  const { width, height } = documentSize({
    width: barcodeSpecification?.size.width || 0,
    height: barcodeSpecification?.size.height || 0,
    unit: barcodeSpecification?.size.unit || UnitSystem.IMPERIAL,
  });

  const aspectRatio = width / height;
  const svgWidth = 500;
  const svgHeight = svgWidth / aspectRatio || svgWidth;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
      }}
    >
      <svg
        id="label-preview-svg"
        ref={svgRef}
        height={`${svgHeight}px`}
        width={`${svgWidth}px`}
        viewBox={`0 0 ${width || 500} ${height || 500}`}
        style={{ border: '5px solid black', borderRadius: 5 }}
      >
        <text x={250} y={250} fontSize={20} fill="black" textAnchor="middle">
          Input Specification Details to Preview Label
        </text>
      </svg>
      {svgRef.current && barcodeSpecification && (
        <>
          <Button
            onClick={generateLabels}
            color="primary"
            variant="contained"
            disabled={loading}
            style={{ width: 500, height: 48 }}
          >
            Generate Labels
          </Button>
          <Typography variant="caption">
            Will generate {all_samples_count} label
            {all_samples_count === 1 ? '' : 's'}
          </Typography>
          {all_samples_count > 999 && (
            <Typography variant="caption">
              Rendering may take a while with this many samples. Please be
              patient and keep this window open.
            </Typography>
          )}
        </>
      )}
      {loading && <CircularProgress color="secondary" />}
    </div>
  );
}
function generateSVGs(
  props: BarcodeSubmitProps,
  samples: ProcessedInventoryEntry[],
  fieldDefinitions: FieldDefinition[],
) {
  const documentProps = {
    width: props.size.width,
    height: props.size.height,
    unit: props.size.unit,
  };
  const template = TEMPLATES[props.label_template_definition_id];

  const svgs = LabelRollDrawer({
    samples,
    documentProps,
    labelElements: template,
    fieldKeyMap: Object.fromEntries(
      Object.keys(template).map((label_field_key) => [
        label_field_key,
        props.label_fields[label_field_key],
      ]),
    ),
    fieldDefinitions,
    showFieldName: props.show_field_name,
  });
  const docSize = documentSize(documentProps);

  return { docSize, svgs };
}

async function runGenerateSamplesForPage(
  barcodeSpecification: BarcodeSubmitProps,
  fieldDefinitions: FieldDefinition[],
  all_samples_url: string,
  setCalculatedNumSamples: (numSamples: number) => void,
): Promise<Size2D & { svgs: SVGSVGElement[] }> {
  let next_page_url = all_samples_url;
  const allSVGs = [];
  let docSize = null;
  while (next_page_url) {
    const { samples, next_page_url: next_page_url_from_response } =
      await paginatedGetSamples(next_page_url);

    next_page_url = next_page_url_from_response;
    const processedSamples = samples.map((sample) =>
      InventoryStoreHelper.processInventorySearchEntrySample(
        sample,
        fieldDefinitions,
        [],
      ),
    );

    const { docSize: docSizeFromResponse, svgs } = generateSVGs(
      barcodeSpecification,
      processedSamples as ProcessedInventoryEntry[],
      fieldDefinitions,
    );
    allSVGs.push(...svgs);
    setCalculatedNumSamples(allSVGs.length);
    if (!docSize) {
      docSize = docSizeFromResponse;
    }
  }
  return { width: docSize.width, height: docSize.height, svgs: allSVGs };
}
async function generatePDF(
  docWidth: number,
  docHeight: number,
  svgs: SVGSVGElement[],
) {
  const docSize = [docWidth, docHeight];

  // pdf/jspdf switches height/width so that orientation ratio is maintained
  const orientation = docWidth < docHeight ? 'p' : 'l';

  // this class is exported as lowercase first letter
  // eslint-disable-next-line new-cap
  const doc = new jsPDF(orientation, 'pt', docSize);
  for (const ii of new Array(svgs.length).fill(0).keys()) {
    if (ii > 0) {
      doc.addPage(docSize, orientation);
    }
    await doc.svg(svgs[ii]);
  }
  doc.save(`CDD-Labels-${Date.now().toString()}.pdf`);
}

async function paginatedGetSamples(next_page_url: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const response = await axios.post(next_page_url, {
    mrv: urlParams.get('mrv'),
    text: urlParams.get('text'),
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch samples');
  }
  return response.data;
}
