import { LocationNode, LocationValue } from '@/Samples/types';
import { Card, CircularProgress, Fade } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import clamp from 'lodash/clamp';
import { LocationBoxPosition } from './LocationBoxPosition';
import { observer } from 'mobx-react';
import { useResizeDetector } from 'react-resize-detector';
import {
  LocationUtils,
  MAX_CELL_PADDING,
  MIN_CELL_PADDING,
  MIN_CELL_SIZE,
  ROW_COL_LABEL_SIZE,
} from './locationUtils';

type SharedProps = {
  samples?: Array<{ location: LocationValue, name: string }>;
  value?: LocationValue;
  box: LocationNode;
  onChange?: (value: LocationValue) => void;
  loading?: boolean;
};

type Props = SharedProps & {
  width: number;
  height: number;
};

type OrganizedGridProps = SharedProps & {
  num_columns: number;
  num_rows: number;
  cellSizeWithPadding: number;
  cellPadding: number;
};

class OrganizedBoxGrid extends React.Component<OrganizedGridProps> {
  handleClickElement = (position: number) => {
    this.props.onChange?.({
      id: this.props.box.id,
      position,
      value: '',
    });
  };

  private rowLabel(row: number): React.ReactNode {
    const numTimesGoneThrough26 = Math.floor(row / 26);
    const labelPrefix =
      numTimesGoneThrough26 > 0
        ? String.fromCharCode(numTimesGoneThrough26 + 64)
        : '';
    const remainder = row % 26;
    return labelPrefix + String.fromCharCode(remainder + 65);
  }

  render() {
    const {
      num_columns,
      num_rows,
      cellSizeWithPadding,
      cellPadding,
      value,
      samples,
      loading,
      box,
    } = this.props;
    const positionsWidth = cellSizeWithPadding * num_columns;
    const positionsHeight = cellSizeWithPadding * num_rows;

    // const cellSizeAdjusted = c;
    return (
      <>
        {/* TODO may be worth deduping row & col labels - not completely straightforward */}
        {/* Render column labels */}
        <div className="col-labels" style={{ marginLeft: cellPadding + 8 }}>
          {new Array(num_columns).fill(null).map((v, col) => (
            <label key={col} style={{ width: cellSizeWithPadding }}>
              {col + 1}
            </label>
          ))}
        </div>

        {/* Render row labels */}
        <div
          className="row-labels"
          style={{ marginTop: ROW_COL_LABEL_SIZE, width: ROW_COL_LABEL_SIZE }}
        >
          {new Array(num_rows).fill(null).map((v, row) => (
            <div key={row} style={{ height: cellSizeWithPadding }}>
              <label style={{ width: ROW_COL_LABEL_SIZE }}>
                {this.rowLabel(row)}
              </label>
            </div>
          ))}
        </div>

        <div
          className="positions"
          style={{
            width: positionsWidth,
            height: positionsHeight,
            marginTop: ROW_COL_LABEL_SIZE,
            marginLeft: ROW_COL_LABEL_SIZE,
          }}
        >
          {new Array(num_rows).fill(null).map((_v, row) => (
            <div
              key={row}
              className="row"
              style={{ height: cellSizeWithPadding }}
            >
              {new Array(num_columns).fill(null).map((_v, col) => {
                const position = row * num_columns + col + 1;
                const selected =
                  value?.id === box.id && value.position === position;
                const sample = (samples ?? []).find(
                  (s) => s.location.position === position,
                );
                return (
                  <LocationBoxPosition
                    key={col}
                    position={position}
                    selected={selected}
                    sample={sample}
                    cellSize={cellSizeWithPadding}
                    cellPadding={cellPadding}
                    onClick={this.handleClickElement}
                    index={{ x: col, y: row }}
                    boxSize={{ x: num_columns, y: num_rows }}
                  />
                );
              })}
            </div>
          ))}
        </div>
        <Fade in={loading} appear={false} timeout={500}>
          <div className="loading-mask" data-testid="loading-mask">
            <CircularProgress className="loading-spinner" />
          </div>
        </Fade>
      </>
    );
  }
}

// gets actual width and height of the container and passes it into the fixed size component
export const LocationBoxPicker = (props: Omit<Props, 'width' | 'height'>) => {
  const { width, height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 10,
  });

  return (
    <div className="LocationBoxPickerContainer" ref={ref}>
      {width && height && (
        <LocationBoxPickerFixedSize
          {...props}
          width={width - 10}
          height={height - 10}
        />
      )}
    </div>
  );
};

@observer
class LocationBoxPickerFixedSize extends React.Component<Props> {
  render() {
    const { box, width, height } = this.props;
    if (!this.props.box || LocationUtils.isNodeLocation(this.props.box)) {
      return null;
    }

    const num_columns = box.organized ? box.num_columns : 1;
    const num_rows = box.organized ? box.num_rows : 1;

    const maxDimensions = Math.max(num_columns, num_rows);
    const cellPadding = clamp(MIN_CELL_SIZE / maxDimensions,
      MIN_CELL_PADDING,
      MAX_CELL_PADDING,
    );

    const cellWidth = LocationUtils.calculateCellSize({
      size: width,
      num_boxes: num_columns,
      cellPadding,
    });
    const cellHeight = LocationUtils.calculateCellSize({
      size: height,
      num_boxes: num_rows,
      cellPadding,
    });

    const cellSizeWithPadding = Math.min(cellWidth, cellHeight);

    const { cardDimension: cardWidth, cardPosition: cardLeft } =
      LocationUtils.calculateCardSize({
        size: width,
        num_boxes: num_columns,
        cellSizeWithPadding,
        cellPadding,
      });

    const { cardDimension: cardHeight, cardPosition: cardTop } =
      LocationUtils.calculateCardSize({
        size: height,
        num_boxes: num_rows,
        cellSizeWithPadding,
        cellPadding,
      });
    return (
      <Box className="LocationBoxPicker">
        <Card
          raised={true}
          style={{
            position: 'relative',
            width: cardWidth,
            height: cardHeight,
            left: cardLeft,
            top: cardTop,
          }}
        >
          {box.organized
            ? (
              <OrganizedBoxGrid
                {...this.props}
                num_columns={num_columns}
                num_rows={num_rows}
                cellSizeWithPadding={cellSizeWithPadding}
                cellPadding={cellPadding}
              />
              )
            : (
              <></>
              )}
        </Card>
      </Box>
    );
  }
}
