import { createJSBarcode, createReactQRCode } from './createBarcode';
import { DrawableElement } from '../types';
import {
  DocumentProps,
  LabelFieldDefinition,
  QRCodeSymbology,
  LabelElementMapTemplate,
  SymbologySupportedTypes,
} from './types';
import {
  documentSize,
  elementLocation,
  elementSize,
  mergeSVGs,
  SVG_NS,
} from './drawableUtils';
import {
  CustomOrDefaultResultColumnId,
  ProcessedInventoryEntry,
} from '@/Inventory/components/types';
import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';

/**
 * A Drawable is a class that draws a specific graphical element, within a specifically sized document
 * The document settings are defined in the constructor, the `draw` method is the main interface to actually draw
 * One Drawable can be used to draw multiple different graphical elements on the same label
 * just by calling it with different field properties
 *
 * drawable is meant to be placed so that the center of the element is positioned at the position specified by `x` and `y`
 * so a drawable can be considered as having centers specified like
 *
 *  [0, 1] --------- [1, 1]
 *    |                |
 *    |                |
 *    |   [0.5, 0.5]   |
 *    |                |
 *    |                |
 *  [0, 0] --------- [1, 0]
 **/
// one roll of labels has one page/individual label per sample
export function LabelRollDrawer({
  samples,
  documentProps,
  labelElements,
  fieldKeyMap,
  fieldDefinitions,
  showFieldName,
}: // units,
{
  samples: ProcessedInventoryEntry[];
  documentProps: DocumentProps;
  labelElements: LabelElementMapTemplate;
  // maps from label field keys to inventory field keys
  // ie "what sample value gets put in a specific spot on a label"
  fieldKeyMap: Record<string, CustomOrDefaultResultColumnId>;
  fieldDefinitions: FieldDefinition[];
  showFieldName: boolean;
}) {
  const svgs = samples.map((sample) => {
    return LabelPageDrawer({
      labelElements,
      documentProps,
      sample,
      fieldKeyMap,
      fieldDefinitions,
      showFieldName,
    });
  });
  return svgs;
}

export function LabelPageDrawer({
  labelElements,
  documentProps,
  sample,
  fieldKeyMap,
  fieldDefinitions,
  showFieldName,
}: {
  labelElements: LabelElementMapTemplate;
  documentProps: DocumentProps;
  sample: ProcessedInventoryEntry;
  fieldKeyMap: Record<string, CustomOrDefaultResultColumnId>;
  fieldDefinitions: FieldDefinition[];
  showFieldName: boolean;
}) {
  const baseSVG = document.createElementNS(SVG_NS, 'svg');
  const { width: docWidth, height: docHeight } = documentSize(documentProps);
  baseSVG.setAttribute('width', docWidth.toString());
  baseSVG.setAttribute('height', docHeight.toString());

  Object.keys(labelElements).forEach((labelKey) => {
    const labelElement = labelElements[labelKey];
    const draw = LabelDrawableMap[labelElement.drawableType];
    // key of the inventory fields
    const idIsCustom = Number.isInteger(
      Number.parseInt(fieldKeyMap[labelKey] as string),
    );
    const fieldKey = idIsCustom
      ? Number.parseInt(fieldKeyMap[labelKey] as string)
      : fieldKeyMap[labelKey];

    const value = idIsCustom
      ? sample.inventory_sample_fields[fieldKey]
      : sample[fieldKey];

    const fieldDefinition = fieldDefinitions.find(
      (field) => field.id === fieldKey,
    );
    const parsedValue =
      fieldDefinition.data_type_name == FieldDataType.Number
        ? Number.parseInt(value as string)
        : value;

    if (
      !labelElement.fieldSchema.supportedDataTypes.includes(
        fieldDefinition.data_type_name,
      )
    ) {
      throw new Error('Unsupported Data Type for this Label');
    }

    const svg = draw({
      ...labelElement,
      documentProps,
      labelValue: {
        value: parsedValue || 'N/A',
        data_type_name: fieldDefinition.data_type_name,
        fieldName: fieldDefinition.name,
      },
      otherProps: {
        showFieldName,
      },
    });
    const groupElement = document.createElementNS(SVG_NS, 'g');

    groupElement.appendChild(svg);
    // eslint-disable-next-line no-unsafe-innerhtml/no-unsafe-innerhtml
    baseSVG.innerHTML = mergeSVGs({ svg1: baseSVG, svg2: groupElement });
  });
  return baseSVG;
}
type LabelDrawableProps<
  TDrawableElement extends DrawableElement = DrawableElement,
> = Omit<
  LabelFieldDefinition<TDrawableElement>,
  'svg' | 'documentProps' | 'fieldKey'
> & {
  documentProps: DocumentProps;

  labelValue: {
    value: string;
    data_type_name: FieldDataType;
    fieldName: string;
  };
  otherProps: { showFieldName: boolean };
};
type LabelDrawable = (props: LabelDrawableProps) => SVGElement | HTMLElement;
const LabelDrawableMap: Record<DrawableElement, LabelDrawable> = {
  [DrawableElement.Text]: TextLabelDrawable,
  [DrawableElement.Barcode]: BarcodeLabelDrawable,
};

export function BarcodeLabelDrawable({
  fieldSchema,
  labelValue: { value, data_type_name },
  documentProps,
}: LabelDrawableProps<DrawableElement.Barcode>) {
  if (
    !SymbologySupportedTypes[fieldSchema.symbology].includes(data_type_name)
  ) {
    throw new Error('Unsupported Data Type for this Symbology');
  }
  const { height, width } = elementSize({ fieldSchema, documentProps });
  if (fieldSchema.symbology == QRCodeSymbology.QRCode) {
    return createReactQRCode({
      value,
      fieldSchema,
      documentProps,
    });
  } else {
    return createJSBarcode({
      value,
      barcodeWidth: width,
      options: {
        height,
        margin: 0,
        displayValue: false,
        format: fieldSchema.symbology,
      },
      fieldSchema,
      documentProps,
    });
  }
}

export function TextLabelDrawable({
  fieldSchema,
  labelValue: { value, fieldName },
  documentProps,
  otherProps: { showFieldName },
}: LabelDrawableProps): SVGElement {
  // div used to size a text element - if the text doesn't exist in the DOM then the size will be 0
  let sizingBox = document.getElementById('sizingBox');
  if (sizingBox == null) {
    sizingBox = document.createElement('div');
    sizingBox.id = 'sizingBox';
    document.body.appendChild(sizingBox);
  }
  const { width: boxWidth, height: boxHeight } = elementSize({
    fieldSchema,
    documentProps,
  });
  const { x, y } = elementLocation({
    fieldSchema,
    documentProps,
  });
  const svg = document.createElementNS(SVG_NS, 'svg');
  const text = document.createElementNS(SVG_NS, 'text');

  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.textContent = showFieldName ? `${fieldName}: ${value}` : value;
  sizingBox.appendChild(svg);

  let fontSize = 100;
  text.setAttribute('font-size', fontSize + 'px');
  svg.appendChild(text);
  let bbox = text.getBBox();

  while (bbox.width > boxWidth || bbox.height > boxHeight) {
    fontSize -= 1;
    text.setAttribute('font-size', fontSize + 'px');
    bbox = text.getBBox();
  }
  sizingBox.removeChild(svg);
  text.setAttribute('x', x.toString());
  text.setAttribute('y', y.toString());

  return svg;
}
