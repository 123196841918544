import { FieldDefinition } from '@/FieldDefinitions/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import {
  SelectMultipleDef,
  SelectSingleDef,
} from '@/shared/components/DDForm/types';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { AnyObject } from '@/types';
import {
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { DrawableElement, LabelTemplateDefinition } from '@/Labels/types';
import { CustomOrDefaultResultColumnId } from './components/types';
import { TestableSelect } from '@/shared/components/TestableMuiComponents/TestableSelect';
import {
  LabelTemplateKey,
  TEMPLATES,
} from '@/Labels/Computation/label_templates';
import {
  DocumentProps,
  LabelFieldDefinition,
} from '@/Labels/Computation/types';
import { getRootStore } from '@/stores/rootStore';
import { UnitSystem } from '@/stores/localPersistenceStore';

export type BarcodeSubmitProps = {
  label_template_definition_id: LabelTemplateKey;
  size: Pick<DocumentProps, 'width' | 'height' | 'unit'>;
  label_fields: Record<number, CustomOrDefaultResultColumnId>;
  show_field_name: boolean;
};

type Props = {
  labelTemplateDefinitions: LabelTemplateDefinition[];
  fieldDefinitions: FieldDefinition[];
  onSubmit: (value: BarcodeSubmitProps) => void;
  loading: boolean;
};

type FormState = {
  errors: AnyObject;
  data: BarcodeSubmitProps;
};

const { column, row, numberInput, select, checkbox, typography } =
  layoutBuilder;

@observer
export class BarcodeSettings extends React.Component<Props> {
  formState: FormState = {
    data: {
      label_template_definition_id: null,
      size: { height: 1, width: 1, unit: UnitSystem.IMPERIAL },
      label_fields: {},
      show_field_name: false,
    },
    errors: {},
  };

  localPersistenceStore = getRootStore().localPersistenceStore;
  constructor(props: Props) {
    super(props);
    if (this.localPersistenceStore.data.preferredUnit) {
      this.formState.data.size.unit =
        this.localPersistenceStore.data.preferredUnit;
    }
    makeObservable(this, {
      formState: observable,
    });
  }

  get activeLabelTemplateDefinition() {
    return this.formState.data.label_template_definition_id;
  }

  get sizeRow() {
    return row([
      numberInput({
        label: 'Label Width',
        id: 'barcode-width',
        key: '.size.width',
        required: true,
      }),
      numberInput({
        label: 'Label Height',
        id: 'barcode-height',
        key: '.size.height',
        required: true,
      }),
      select({
        label: 'Unit',
        required: true,
        key: '.size.unit',
        width: '50%',
        selectOptions: [
          { id: UnitSystem.IMPERIAL, label: 'Inches' },
          { id: UnitSystem.METRIC, label: 'Centimeters' },
        ],
      }),
    ]);
  }

  get showFieldNameRow() {
    return row([
      checkbox({
        id: 'show-field-name',
        label: 'Show Field Name',
        key: 'show_field_name',
      }),
    ]);
  }

  fieldRow(
    labelFieldDefinition: LabelFieldDefinition,
  ): SelectSingleDef | SelectMultipleDef {
    const { fieldDefinitions } = this.props;
    const filteredFieldDefinitions = fieldDefinitions.filter((val) =>
      labelFieldDefinition.fieldSchema.supportedDataTypes.includes(
        val.data_type_name,
      ),
    );

    const fieldLimitations: string[] = [
      ...labelFieldDefinition.fieldSchema.supportedDataTypes,
    ];
    if (labelFieldDefinition.fieldValuesUnique) {
      fieldLimitations.push('Unique');
    }
    return select({
      className: 'field-def-values',
      label: `${
        labelFieldDefinition.name
      } (${labelFieldDefinition.fieldSchema.supportedDataTypes.join(', ')})`,
      required: true,
      selectOptions: [
        ...filteredFieldDefinitions.map((val) => ({
          ...val,
          label: val.name,
        })),
      ],
      key: `label_fields.${labelFieldDefinition.name}`,
    });
  }

  // all field rows for a given drawable type, gets a label
  fieldRowGroup(
    group: (SelectSingleDef | SelectMultipleDef)[],
    groupName: DrawableElement,
  ) {
    return column([
      typography({
        label: `${groupName}:`,
        className: 'drawable-group-label',
      }),
      column(
        { className: 'label-field-defs', id: `label-field-def-${groupName}` },
        group,
      ),
    ]);
  }

  get layout() {
    const rows: Array<RowColumnDef | SelectSingleDef | SelectMultipleDef> = [];
    rows.push(this.labelTemplateDropdown());
    rows.push(this.sizeRow);
    // don't show empty groups
    if (this.groupedLabelFieldRows[DrawableElement.Barcode].length > 0) {
      const barcodeGroup = this.fieldRowGroup(
        this.groupedLabelFieldRows[DrawableElement.Barcode],
        DrawableElement.Barcode,
      );
      rows.push(barcodeGroup);
    }
    if (this.groupedLabelFieldRows[DrawableElement.Text].length > 0) {
      const textGroup = this.fieldRowGroup(
        this.groupedLabelFieldRows[DrawableElement.Text],
        DrawableElement.Text,
      );
      rows.push(textGroup);
      rows.push(this.showFieldNameRow);
    }
    return column({ spacing: 2, id: 'barcode-field-defs' }, rows);
  }

  get hasLabelTemplateDefinition() {
    return this.activeLabelTemplateDefinition !== null;
  }

  handleSubmit = () => {
    this.localPersistenceStore.setData({
      preferredUnit: this.formState.data.size.unit,
    });
    this.props.onSubmit(this.formState.data);
  };

  labelTemplateDropdown() {
    return select({
      id: 'label-template-select',
      label: 'Label Template',
      selectOptions: this.props.labelTemplateDefinitions.map(
        (templateDefinition) => ({
          id: templateDefinition.id,
          label: templateDefinition.name,
        }),
      ),
      key: 'label_template_definition_id',
    });
  }

  render() {
    const { loading } = this.props;
    const { hasLabelTemplateDefinition, handleSubmit } = this;
    return (
      <DialogContent
        className="muiDialog-content-400"
        style={{
          border: '5px solid black',
          borderRadius: 5,
          margin: 10,
          alignContent: 'center',
          justifyItems: 'center',
        }}
      >
        <Stack
          direction="column"
          style={{ paddingBottom: '12px', width: '70%' }}
          gap={2}
        >
          <CDDModalForm
            data={this.formState.data}
            onOK={handleSubmit}
            terminology={{ OK: 'Preview Label' }}
            okDisabled={loading}
            formState={this.formState}
            layout={this.layout}
          />
        </Stack>
      </DialogContent>
    );
  }

  get activeLabelFieldDefinitions(): LabelFieldDefinition[] {
    const activeTemplate = this.props.labelTemplateDefinitions.find(
      (val) => val.id === this.activeLabelTemplateDefinition,
    );
    return activeTemplate?.fieldDefinitions || [];
  }

  get groupedLabelFieldRows() {
    return Object.values(this.activeLabelFieldDefinitions).reduce(
      (acc, val) => {
        acc[val.drawableType].push(this.fieldRow(val));
        return acc;
      },
      {
        [DrawableElement.Barcode]: [],
        [DrawableElement.Text]: [],
      } as Record<DrawableElement, (SelectSingleDef | SelectMultipleDef)[]>,
    );
  }
}
