export enum MacromoleculeType {
  PeptideLinear,
  PeptideCyclic,
  Nucleotide,
}

export enum LinearWrap {
  None,
  Winding,
  Typewriter,
}

export enum CycleOrientation {
  North,
  East,
  South,
  West,
}

export enum CycleDirection {
  Clockwise,
  Anticlock,
}

export enum NucleotideType {
  RNA,
  DNA,
}

export class DataOptions {
  sequenceColumn: number;
  sequenceType: MacromoleculeType;
  linearWrap: LinearWrap;
  linearWidth: number;
  cycleOrientation: CycleOrientation;
  cycleDirection: CycleDirection;
  nucleotideType: NucleotideType;
  doubleStranded: boolean;
}

export const DEFAULT_DATA_OPTIONS: DataOptions = {
  sequenceColumn: null,
  sequenceType: null,
  linearWrap: LinearWrap.None,
  linearWidth: 12,
  cycleOrientation: CycleOrientation.North,
  cycleDirection: CycleDirection.Clockwise,
  nucleotideType: NucleotideType.RNA,
  doubleStranded: false,
};

export interface BuiltResultsCache {
  sequence: string;
  molfile?: string;
  error?: string;
  thumbnailSVG?: string;
  previewSVG?: string;
}
