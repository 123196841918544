import { Location2D, Size2D } from '@/types';
import type {
  FieldSchema,
  DocumentProps,
  NumberPercentZeroToOne,
} from './types';
import { UnitSystem } from '@/stores/localPersistenceStore';
export const PDF_PT_PER_INCH = 72;
export const INCHES_PER_CM = 2.54;
export const SVG_NS = 'http://www.w3.org/2000/svg' as const;

// small helpers to add semantic typing to the values & validate if necessary to avoid misplaced values
export const PercentZeroToOne = (val: number): NumberPercentZeroToOne => {
  if (val < 0 || val > 1) {
    throw new Error('Invalid size!');
  }
  return val as NumberPercentZeroToOne;
};

export function mergeSVGs({
  svg1,
  svg2,
}: {
  svg1: SVGElement | HTMLElement;
  svg2: SVGElement | HTMLElement;
}): string {
  return svg1.innerHTML + svg2.innerHTML;
}

export function elementSize({
  fieldSchema,
  documentProps,
}: {
  // FieldSchema is specified in % of the document size between 0 and 1
  fieldSchema: FieldSchema;
  documentProps: DocumentProps;
}): Size2D {
  const { width: docWidth, height: docHeight } = documentSize(documentProps);
  return {
    width: fieldSchema.width * docWidth,
    height: fieldSchema.height * docHeight,
  };
}

export function elementLocation({
  fieldSchema,
  documentProps,
}: {
  fieldSchema: FieldSchema;
  documentProps: DocumentProps;
}): Location2D {
  const { width: docWidth, height: docHeight } = documentSize(documentProps);
  return {
    x: fieldSchema.x * docWidth,
    y: fieldSchema.y * docHeight,
  };
}

export function documentSize(documentProps: DocumentProps): Size2D {
  const unitFactor =
    documentProps.unit === UnitSystem.IMPERIAL
      ? PDF_PT_PER_INCH
      : PDF_PT_PER_INCH * INCHES_PER_CM;
  return {
    width: documentProps.width * unitFactor,
    height: documentProps.height * unitFactor,
  };
}
