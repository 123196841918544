// x & y refer to the center of the drawable, in terms of % of label size.
// Width & Height are also in terms of % of label size.
// longer term these are intended to be created by the user, but hardcoding some examples for now

import { FieldDataType } from '@/FieldDefinitions/types';
import { DrawableElement } from '../types';
import { PercentZeroToOne } from './drawableUtils';
import {
  JSBarcodeSymbology,
  QRCodeSymbology,
  SymbologySupportedTypes,
  type LabelElementMapTemplate,
} from './types';

const BASIC_BARCODE_128_SCHEMA: LabelElementMapTemplate = {
  Barcode: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.5),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.8),
      symbology: JSBarcodeSymbology.CODE128,
      supportedDataTypes: SymbologySupportedTypes[JSBarcodeSymbology.CODE128],
    },
    fieldValuesUnique: true,
    name: 'Barcode',
    drawableType: DrawableElement.Barcode,
  },
};

const BASIC_BARCODE_QR_SCHEMA: LabelElementMapTemplate = {
  Barcode: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.5),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.8),
      symbology: QRCodeSymbology.QRCode,
      supportedDataTypes: SymbologySupportedTypes[QRCodeSymbology.QRCode],
    },
    fieldValuesUnique: true,
    name: 'Barcode',
    drawableType: DrawableElement.Barcode,
  },
};

const BARCODE_128_LABEL_SCHEMA: LabelElementMapTemplate = {
  Barcode: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.6),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.5),
      symbology: JSBarcodeSymbology.CODE128,
      supportedDataTypes: SymbologySupportedTypes[JSBarcodeSymbology.CODE128],
    },
    fieldValuesUnique: true,
    name: 'Barcode',
    drawableType: DrawableElement.Barcode,
  },
  Text: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.2),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.3),
      supportedDataTypes: [FieldDataType.Text, FieldDataType.Number],
    },
    fieldValuesUnique: false,
    name: 'Text',
    drawableType: DrawableElement.Text,
  },
};

const BARCODE_QR_LABEL_SCHEMA: LabelElementMapTemplate = {
  Barcode: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.6),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.5),
      symbology: QRCodeSymbology.QRCode,
      supportedDataTypes: SymbologySupportedTypes[QRCodeSymbology.QRCode],
    },
    fieldValuesUnique: true,
    name: 'Barcode',
    drawableType: DrawableElement.Barcode,
  },
  Text: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.2),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.3),
      supportedDataTypes: [FieldDataType.Text, FieldDataType.Number],
    },
    fieldValuesUnique: false,
    name: 'Text',
    drawableType: DrawableElement.Text,
  },
};
const BARCODE_128_TWO_TEXT_ELEMENTS: LabelElementMapTemplate = {
  Barcode: {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.7),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.4),
      symbology: JSBarcodeSymbology.CODE128,
      supportedDataTypes: SymbologySupportedTypes[JSBarcodeSymbology.CODE128],
    },
    fieldValuesUnique: true,
    name: 'Barcode',
    drawableType: DrawableElement.Barcode,
  },
  'First Text': {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.2),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.2),
      supportedDataTypes: [FieldDataType.Text, FieldDataType.Number],
    },
    fieldValuesUnique: false,
    name: 'First Text',
    drawableType: DrawableElement.Text,
  },
  'Second Text': {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.4),
      width: PercentZeroToOne(0.8),
      height: PercentZeroToOne(0.2),
      supportedDataTypes: [FieldDataType.Text, FieldDataType.Number],
    },
    fieldValuesUnique: false,
    name: 'Second Text',
    drawableType: DrawableElement.Text,
  },
};
const TWO_BARCODES_ONE_TEXT_LABEL: LabelElementMapTemplate = {
  'Code 128': {
    fieldSchema: {
      x: PercentZeroToOne(0.5),
      y: PercentZeroToOne(0.75),
      width: PercentZeroToOne(0.9),
      height: PercentZeroToOne(0.4),
      symbology: JSBarcodeSymbology.CODE128,
      supportedDataTypes: SymbologySupportedTypes[JSBarcodeSymbology.CODE128],
    },
    fieldValuesUnique: true,
    name: 'Code 128',
    drawableType: DrawableElement.Barcode,
  },
  'QR Code': {
    fieldSchema: {
      x: PercentZeroToOne(0.25),
      y: PercentZeroToOne(0.25),
      width: PercentZeroToOne(0.4),
      height: PercentZeroToOne(0.4),
      symbology: QRCodeSymbology.QRCode,
      supportedDataTypes: SymbologySupportedTypes[QRCodeSymbology.QRCode],
    },
    fieldValuesUnique: true,
    name: 'QR Code',
    drawableType: DrawableElement.Barcode,
  },
  Text: {
    fieldSchema: {
      x: PercentZeroToOne(0.75),
      y: PercentZeroToOne(0.3),
      width: PercentZeroToOne(0.4),
      height: PercentZeroToOne(0.4),
      supportedDataTypes: [FieldDataType.Text, FieldDataType.Number],
    },
    fieldValuesUnique: false,
    name: 'Text',
    drawableType: DrawableElement.Text,
  },
};

export const TEMPLATES = {
  // Currently not supported - Structure rendering not yet implemented for first pass
  // 'Barcode And Structure': BARCODE_WITH_STRUCTURE,
  'Barcode (Code 128)': BASIC_BARCODE_128_SCHEMA,
  'Barcode (QR Code)': BASIC_BARCODE_QR_SCHEMA,
  'Barcode (Code 128), Text Label': BARCODE_128_LABEL_SCHEMA,
  'Barcode (QR Code), Text Label': BARCODE_QR_LABEL_SCHEMA,
  'Barcode (Code 128), 2 Text Labels': BARCODE_128_TWO_TEXT_ELEMENTS,
  'Barcode (Code 128), Barcode (QR Code), Text Label':
    TWO_BARCODES_ONE_TEXT_LABEL,
};

export type LabelTemplateKey = keyof typeof TEMPLATES;
