import { FieldDataType } from '@/FieldDefinitions/types';
import { Sample } from '@/Samples/types';
import { FieldSchema } from './Computation/types';

export type SVGString = string & { __brand: 'SVGString' };
export type NumberBetweenZeroAndOne = number & {
  __brand: 'NumberBetweenZeroAndOne';
};
export enum DrawableElement {
  Text = 'Text',
  Barcode = 'Barcode',
}

export type LabelFieldDefinition = {
  data_type_name: FieldDataType;
  fieldValuesUnique: boolean;
  drawableType: DrawableElement;
  field_is_optional: boolean;
  label_template_definition_id: number;
  id: number;
  name: string;
  fieldSchema: FieldSchema;
};

export type LabelTemplateDefinition = {
  id: string;
  name: string;
  fieldDefinitions: LabelFieldDefinition[];
};
export type LabelField = {
  fieldDefinition: LabelFieldDefinition;
  value: string;
  templateDefinition: LabelTemplateDefinition;
};
export type LabelTemplate = {
  height_pt: number;
  width_pt: number;
};
export enum LabelDrawableEvent {
  Progress = 'Progress',
  Result = 'Result',
  Error = 'Error',
}
