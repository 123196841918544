import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { GetValueMap } from '@/Search/SearchFilters/GetValue.types';
import { GlobalMessage } from '@/shared/components/GlobalMessage/GlobalMessage';
import { getRootStore } from '@/stores/rootStore';
import { CDD } from '@/typedJS';
import { Link } from '@mui/material';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';
import { InventorySearchDialogs } from './InventorySearchDialogs';
import './InventorySearchView.sass';
import { SearchBar } from './components/SearchBar/SearchBar';
import { InventorySearchTable } from './components/Table/InventorySearchTable';
import {
  InventorySearchViewInitialLoadProps,
  LocationAncestryRequestProps,
  LocationRequestProps,
} from './components/types';
import { InventoryStoreHelper } from './stores/InventoryStoreHelper';

function InventoryErrorBoundary({
  resetInventoryPreferences,
}: {
  resetInventoryPreferences: () => Promise<void>;
}): JSX.Element {
  // since some errors can also go wrong by the time we get the store values, we have this fallback
  const reset = async () => {
    await resetInventoryPreferences();
    window.location.reload();
  };
  return (
    <div>
      Something Went Wrong.{' '}
      <Link data-testid="reset-preferences" onClick={reset}>
        Resetting your search preferences might help.
      </Link>
    </div>
  );
}

@observer
class InventorySearchViewInternal extends React.Component<InventorySearchViewInitialLoadProps> {
  private store = getRootStore().inventoryStore;

  constructor(props: InventorySearchViewInitialLoadProps) {
    super(props);
    this.store.setupInventoryURLs(props);
    if (!this.store.setup) {
      this.setupPage();
    }
    // To update shown entries whenever available vaults get changed
    // TODO should probably more broadly refactor away from this kinda jquery pattern
    CDD.DataSourceSelection.updateCallback = this.store.submitNewQuery;
  }

  private async setupPage() {
    const props = await this.store.getInitialProps();
    const inventory_field_definitions_entries =
      InventoryStoreHelper.combineInventoryFields(
        props.custom_field_definitions,
        props.default_field_definitions,
      );
    this.store.setupInventorySearchPage({
      ...props,
      inventory_field_definitions: inventory_field_definitions_entries,
    });
  }

  get links() {
    const { links } = this.props;
    return {
      entriesExportProgressUrl: links.inventory_entries_export_progress_url,
      entriesExportUrl: links.inventory_entries_export_url,
    };
  }

  get FilterValueSelectGetValuesMap(): GetValueMap {
    const {
      getInventoryLocations,
      getAutocompleteOptions,
      getLocationAncestryString,
    } = this.store;
    return {
      [FieldDataType.Location]: async (
        args: LocationRequestProps | LocationAncestryRequestProps,
      ) => {
        return 'location_id' in args
          ? await getLocationAncestryString(args)
          : await getInventoryLocations(args);
      },
      [FieldDataType.UniqueList]: getAutocompleteOptions,
    };
  }

  get searchBarHandlers() {
    const {
      removeQueryFilter,
      updateQueryFilter,
      updateQueryMRV,
      addQueryFilter,
      updateQueryText,
      resetQueryFilters,
      submitNewQuery,
    } = this.store;

    return {
      updateQueryMRV,
      removeQueryFilter,
      updateQueryFilter,
      addQueryFilter,
      updateQueryText,
      submitQuery: submitNewQuery,
      resetQueryFilters,
      getValues: this.FilterValueSelectGetValuesMap,
    };
  }

  get sortProps() {
    const { sortedColumn, setSortBy } = this.store;
    return { sortBy: sortedColumn, setSortBy };
  }

  render(): React.ReactNode {
    const {
      loading,
      inventoryEntries,
      searchQuery,
      visibleColumns,
      availableColumns,
      totalCount,
      hasErrors,
      availableFilters,
      vault_name_map: vaultNameMap,
      user_structure_masked,
      custom_field_definitions: { Event: eventFDs },
      inventory_id_list_fd_ids,
      exportLinks,
      sampleTerminology,
    } = this.store;

    if (hasErrors) {
      throw new Error('Trigger Boundary');
    }

    return (
      <>
        <div id="inventorySearchView">
          <SearchBar
            sharedProps={{
              searchQuery,
              sampleTerminology,
            }}
            handlers={this.searchBarHandlers}
            exportLinksProps={{
              links: exportLinks,
            }}
            searchFilters={{
              availableFilters,
              vaultNameMap,
              user_structure_masked,
              id_list_fd_ids: inventory_id_list_fd_ids,
            }}
            entriesCount={{
              showingCount: inventoryEntries.length,
              totalCount,
            }}
          />
          {this.store.setup && !this.store.hasErrors && (
            <>
              <InventorySearchTable
                {...this.sortProps}
                fieldDefinitions={this.store.inventoryFieldDefinitions}
                hasMore={inventoryEntries.length < totalCount && !hasErrors}
                getNextPage={this.store.getNextPage}
                onChangeVisibleColumns={this.store.setVisibleColumnDetails}
                onClickEntry={this.store.handleEditInventory}
                onToggleSampleDepleted={this.store.handleToggleDeplete}
                availableColumns={availableColumns}
                visibleColumns={visibleColumns}
                entryRows={inventoryEntries}
                loading={loading}
                highlights={this.store.searchHighlights}
                presorted
                locationColumnId={
                  eventFDs.find((val) => val.name === 'Location').id as number
                }
              />
              <InventorySearchDialogs />
            </>
          )}
        </div>
      </>
    );
  }
}
@observer
export class InventorySearchView extends React.Component<InventorySearchViewInitialLoadProps> {
  private store = getRootStore().inventoryStore;

  render() {
    return (
      <>
        {this.store.hasErrors && <GlobalMessage messages={this.store.errors} />}
        <ErrorBoundary
          fallback={
            <InventoryErrorBoundary
              resetInventoryPreferences={this.store.resetInventoryPreferences}
            />
          }
        >
          <InventorySearchViewInternal {...this.props} />
        </ErrorBoundary>
      </>
    );
  }
}
