import React, { useEffect, useState } from 'react';
import { MolRenderOptions, NodeLocation, NodeLocationType, RenderMoleculeSVG } from '@cdd/ui-kit/lib/molRendering/v2/RenderMoleculeSVG';
import { Box, SxProps, Theme } from '@mui/material';
import { ensureChemUtils } from '@/shared/utils/chemUtils';

export const InteractiveChemistryImage = (props: {
  src: string;
  width: number;
  height: number;
  options?: Partial<MolRenderOptions>;
  highlightedAtomNumbers?: number[];
  onClick?: (idx: number, type: NodeLocationType) => void;
  sx?: SxProps<Theme>
}) => {
  const { src, width, height, highlightedAtomNumbers, onClick, sx = {} } = props;
  const options = { minimumAtomPadding: 0.5, ...props.options };

  const [svg, setSvg] = useState<string | null>(null);
  const [nodeLocations, setNodeLocations] = useState<NodeLocation[]>([]);
  const [converter] = useState(new SmilesToMolConverter());

  useEffect(() => {
    converter.is_ready().then(() => {
      const structure = converter.convert(src);
      const render = new RenderMoleculeSVG(structure, width, height, [], options || {}, null, false, highlightedAtomNumbers);
      render.generate();
      setNodeLocations(render.nodeLocations);
      setSvg(render.svg);
    });
  }, [converter, src, width, height, highlightedAtomNumbers]);

  const handleClick = (e: React.MouseEvent) => {
    if (!nodeLocations || !onClick) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const { node } = getClosestNode(nodeLocations, e.clientX - rect.left, e.clientY - rect.top);
    onClick(node.atomIndex, node.nodeType);
  };

  const svgString = `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
  return (
    <Box className='ChemistryImage'
      onClick={onClick && handleClick}
      width={width}
      height={height}
      sx={{ ...sx, cursor: onClick ? 'pointer' : 'default' }}
    >
      {/* eslint-disable-next-line react/forbid-dom-props */}
      {svg ? <img src={svgString} /> : 'Loading...'}
    </Box >
  );
};

export class SmilesToMolConverter {
  chemUtils: ChemUtils | null = null;
  constructor() {
    this.chemUtils = null;
  }

  async is_ready(): Promise<boolean> {
    this.chemUtils = await ensureChemUtils();
    return !!this.chemUtils;
  }

  convert(structure: string): string | null {
    if (isMolfile(structure)) {
      return structure;
    }
    if (!this.chemUtils) {
      return null;
    }
    return this.chemUtils.smilesToMol(structure);
  }
}

const isMolfile = (structure: string) => {
  return !!/^M\s+END\s*/m.exec(structure);
};

const getClosestNode = (nodeLocations: NodeLocation[], x: number, y: number) => {
  const { node, dist2 } = nodeLocations.reduce((closest, node) => {
    const dist2 = (node.cx - x) ** 2 + (node.cy - y) ** 2;
    return dist2 < closest.dist2 ? { node, dist2 } : closest;
  }, { node: null, dist2: Infinity });
  return { node, dist: Math.sqrt(dist2) };
};
