import { deepClone } from '@/Annotator/data/utils';
import { areasEqual, ParserUtil, ParsingArea, ParsingFixedValue, ParsingPlateBlock, ParsingPlateBlockMultiStamp, ParsingSection, ParsingTabularData, ParsingType, TabbedCells } from './parserUtil';

/*
  Looks for plate blocks of repeating variety (same tab & all tabs) and generates any implied blocks.
*/

interface RowSpan {
  y1: number;
  y2: number;
}

interface RepeatingCell {
  area: ParsingArea;
  plates: ParsingPlateBlock[];
}

interface RepeatingRow {
  allTabs: boolean;
  y1: number;
  y2: number;
  cells: RepeatingCell[];
}

function axisOverlaps(a: number, b: number, c: number, d: number): boolean {
  if (a >= c && a <= d) return true;
  if (b >= c && b <= d) return true;
  if (c >= a && c <= b) return true;
  if (d >= a && d <= b) return true;
  if (a <= c && b >= d) return true;
  if (c <= a && d >= b) return true;
  return false;
}

export class ParserImpliedStampBlocks {
  public impliedBlocks: ParsingPlateBlock[] = [];

  private tabReservedRows = new Map<number, RowSpan[]>();

  constructor(private parsingSections: ParsingSection[], private sheets: TabbedCells[]) {
  }

  public perceive(): void {
    const nsheets = this.sheets.length;

    for (let n = 0; n < nsheets; n++) {
      this.tabReservedRows.set(n, []);
    }

    for (const section of this.parsingSections) {
      const reserved = this.tabReservedRows.get(section.tabIndex);
      if (section.type == ParsingType.FixedValue) {
        const { areaValue } = section as ParsingFixedValue;
        this.assimilateReserved(reserved, areaValue);
      } else if (section.type == ParsingType.TabularData) {
        const { areaContent } = section as ParsingTabularData;
        this.assimilateReserved(reserved, areaContent);
      } else if (section.type == ParsingType.PlateBlock) {
        const { areaBlock } = section as ParsingPlateBlock;
        this.assimilateReserved(reserved, areaBlock);
      }
    }

    for (let n = 0; n < nsheets; n++) {
      this.processSheet(n);
    }
  }

  // for the sheet, look for all repeating plates: apply them to same sheet, and all subsequent sheets of global
  private processSheet(tabIndex: number): void {
    const addPlateToRow = (row: RepeatingRow, area: ParsingArea, plate: ParsingPlateBlock): void => {
      for (const cell of row.cells) {
        if (areasEqual(area, cell.area)) {
          cell.plates.push(plate);
          return;
        }
      }
      row.cells.push({ area, plates: [plate] });
    };

    const assimilatePlate = (rows: RepeatingRow[], plate: ParsingPlateBlock): void => {
      const area: ParsingArea = { x: plate.areaColumns.x, y: plate.areaRows.y, w: plate.areaColumns.w, h: plate.areaRows.h };

      let merged = false;
      for (const row of rows) {
        if (axisOverlaps(area.y, area.y + area.h, row.y1, row.y2)) {
          if (plate.multiStamp != ParsingPlateBlockMultiStamp.Global) row.allTabs = false;
          row.y1 = Math.min(row.y1, area.y);
          row.y2 = Math.max(row.y2, area.y + area.h);
          addPlateToRow(row, area, plate);
          merged = true;
          break;
        }
      }
      if (!merged) {
        rows.push({
          allTabs: plate.multiStamp == ParsingPlateBlockMultiStamp.Global,
          y1: area.y,
          y2: area.y + area.h,
          cells: [{ area, plates: [plate] }],
        });
      }
    };

    const findBlankPlates = (tabIndex: number): RepeatingRow[] => {
      const reserved = this.tabReservedRows.get(tabIndex);
      const rowList: RepeatingRow[] = [];
      let topY = 0;
      while (true) {
        const plateList = ParserUtil.findNextPlateBlocks(tabIndex, this.sheets[tabIndex], topY, null);
        if (!plateList) break;

        const row: RepeatingRow = { allTabs: null, y1: Number.POSITIVE_INFINITY, y2: Number.NEGATIVE_INFINITY, cells: [] };
        for (const plate of plateList) {
          const area: ParsingArea = { x: plate.areaColumns.x, y: plate.areaRows.y, w: plate.areaColumns.w, h: plate.areaRows.h };
          row.y1 = Math.min(row.y1, area.y);
          row.y2 = Math.max(row.y2, area.y + area.h);
          row.cells.push({ area, plates: [plate] });
        }
        if (!this.isInReserved(reserved, row.y1, row.y2)) {
          rowList.push(row);
        }
        topY = row.y2;
      }
      return rowList;
    };

    const sourceRows: RepeatingRow[] = [];
    for (const section of this.parsingSections) {
      if (section.tabIndex != tabIndex || section.type != ParsingType.PlateBlock) continue;
      const plate = section as ParsingPlateBlock;
      if (plate.multiStamp == null) continue;
      assimilatePlate(sourceRows, plate);
    }
    if (sourceRows.length == 0) return;

    const availRows = findBlankPlates(tabIndex);
    this.stampPlates(sourceRows, tabIndex, availRows);

    const globalRows = sourceRows.filter((row) => row.allTabs);
    if (globalRows.length > 0) {
      for (let n = tabIndex + 1; n < this.sheets.length; n++) {
        const availOther = findBlankPlates(n);
        this.stampPlates(sourceRows, n, availOther);
      }
    }
  }

  // given a list of source rows, and a list of available destination rows, stamp from {src -> dst} (with recycle)
  private stampPlates(sourceRows: RepeatingRow[], tabIndex: number, destRows: RepeatingRow[]): void {
    const reserved = this.tabReservedRows.get(tabIndex);

    const copyCellBlock = (fromCell: RepeatingCell, toCell: RepeatingCell): void => {
      if (fromCell.area.w != toCell.area.w || fromCell.area.h != toCell.area.h) return;

      this.assimilateReserved(reserved, toCell.area);

      const offsetX = toCell.area.x - fromCell.area.x, offsetY = toCell.area.y - fromCell.area.y;
      for (const fromPlate of fromCell.plates) {
        const plate = deepClone(fromPlate);

        plate.tabIndex = tabIndex;
        plate.areaBlock.x += offsetX;
        plate.areaBlock.y += offsetY;
        plate.areaColumns.x += offsetX;
        plate.areaColumns.y += offsetY;
        plate.areaRows.x += offsetX;
        plate.areaRows.y += offsetY;
        for (const fv of (plate.fixedValues || [])) {
          if (fv.areaValue) {
            fv.areaValue.x += offsetX;
            fv.areaValue.y += offsetY;
          }
        }
        plate.multiStamp = null;

        this.impliedBlocks.push(plate);
      }
    };

    if (destRows.length == 0) return;
    for (let j = 0; j < destRows.length; j++) {
      const i = j % sourceRows.length;

      const src = sourceRows[i], dst = destRows[j];
      if (src.cells.length != dst.cells.length) {
        // stop processing; could consider moving on to the next row instead
        break;
      }
      for (let n = 0; n < src.cells.length; n++) {
        copyCellBlock(src.cells[n], dst.cells[n]);
      }
    }
  }

  private assimilateReserved(reserved: RowSpan[], area: ParsingArea): void {
    if (!area) return;

    reserved.push({ y1: area.y, y2: area.y + area.h });
    reserved.sort((r1, r2) => r1.y1 - r2.y1);

    for (let n = reserved.length - 1; n > 0; n--) {
      const r1 = reserved[n], r2 = reserved[n - 1];
      if (axisOverlaps(r1.y1, r1.y2, r2.y1, r2.y2)) {
        r1.y1 = Math.min(r1.y1, r2.y1);
        r1.y2 = Math.max(r1.y2, r2.y2);
        reserved.splice(n, 1);
      }
    }
  }

  private isInReserved(reserved: RowSpan[], y1: number, y2: number): boolean {
    for (const look of reserved) if (axisOverlaps(y1, y2, look.y1, look.y2)) return true;
    return false;
  }
}
