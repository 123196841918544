import React from 'react';

import ExportLink, { ExportLinkProps } from '@/shared/components/ExportLink';
import { observer } from 'mobx-react';

export type TExportLink = Pick<
  ExportLinkProps,
  'disabled' | 'label' | 'onClick' | 'hidden' | 'hasNoProgress'
>;

// TODO dedupe with eln entries stuff where possible
export type EntriesExportLinksProps = {
  links: TExportLink[];
};
@observer
class EntryExportLink extends React.Component<{ link: TExportLink }> {
  render() {
    const { disabled, label, onClick, hidden, hasNoProgress } = this.props.link;
    return (
      <ExportLink
        className="search-bar__export_link"
        disabled={disabled}
        onClick={onClick}
        hidden={hidden}
        label={label}
        hasNoProgress={hasNoProgress}
      >
        {label}
      </ExportLink>
    );
  }
}
@observer
export class EntriesExportLinks extends React.Component<EntriesExportLinksProps> {
  render() {
    const { links } = this.props;

    return (
      <>
        {links.map((link, index) => {
          return <EntryExportLink key={index} link={link} />;
        })}
      </>
    );
  }
}
