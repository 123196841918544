import { LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import 'svg2pdf.js';
import { TEMPLATES } from '@/Labels/Computation/label_templates';
import { BarcodeSettings } from './BarcodeSettings';
import { getRootStore } from '@/stores/rootStore';
import { observer } from 'mobx-react';
import { Sample } from '@/Samples/types';
import {
  CategorizedFieldDefinitionsMap,
  ProcessedInventoryEntry,
} from './components/types';
import { EVENT_FIELD_TYPE } from './components/consts';
import { InventoryStoreHelper } from './stores/InventoryStoreHelper';
import './InventoryLabelLoaderView.sass';
import { action, makeObservable, observable } from 'mobx';
import { LabelTemplateDefinition } from '@/Labels/types';
import { PreviewLabel } from './PreviewLabel';

type Props = {
  samples: Sample[];
  default_field_definitions: CategorizedFieldDefinitionsMap;
  custom_field_definitions: CategorizedFieldDefinitionsMap;
  all_samples_count: number;
  all_samples_url: string;
};

@observer
export class InventoryLabelLoaderView extends React.Component<Props> {
  private inventoryStore = getRootStore().inventoryStore;

  constructor(props: Props) {
    super(props);
    const inventory_field_definitions_entries =
      InventoryStoreHelper.combineInventoryFields(
        props.custom_field_definitions,
        props.default_field_definitions,
      );

    this.inventoryStore.setupInventoryFieldDefinitions({
      inventory_field_definitions: inventory_field_definitions_entries,
      custom_field_definitions: props.custom_field_definitions,
    });
    this.samples = InventoryStoreHelper.processInventorySearchEntries(
      props.samples,
      this.inventoryStore.inventory_sample_field_definitions,
      this.inventoryStore.inventory_event_field_definitions,
    );
    makeObservable(this, {
      progressPerc: observable,
      setProgressPerc: action,
      loading: observable,
      setLoading: action,
    });
  }

  get barcodeSpecification() {
    return this.inventoryStore.barcodeSpecification;
  }

  samples: ProcessedInventoryEntry[] = [];

  progressPerc = 0;

  setProgressPerc = (progressPerc: number) => {
    console.log('progressPerc', { progressPerc, current: this.progressPerc });
    this.progressPerc = progressPerc;
  };

  loading = false;

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  render() {
    const templateKeys = Object.keys(TEMPLATES);

    const {
      default_field_definitions,
      custom_field_definitions,
      all_samples_count,
      all_samples_url,
    } = this.props;
    const relevantDefaultFieldDefinitions = Object.keys(
      default_field_definitions,
    )
      .filter((key) => key !== EVENT_FIELD_TYPE)
      .map((key) => default_field_definitions[key])
      .flat();
    const relevantCustomFieldDefinitions = Object.keys(custom_field_definitions)
      .filter((key) => key !== EVENT_FIELD_TYPE)
      .map((key) => custom_field_definitions[key])
      .flat();
    const fieldDefinitions = [
      ...relevantDefaultFieldDefinitions,
      ...relevantCustomFieldDefinitions,
    ];
    const labelTemplateDefinitions: LabelTemplateDefinition[] =
      templateKeys.map((value) => ({
        name: value,
        id: value,
        fieldDefinitions: TEMPLATES[value],
      }));
    return (
      <div>
        <div className="inventory-label-loader-view">
          <BarcodeSettings
            loading={this.loading}
            labelTemplateDefinitions={labelTemplateDefinitions}
            fieldDefinitions={fieldDefinitions}
            onSubmit={this.inventoryStore.updateSpecification}
          />
          <PreviewLabel
            loading={this.loading}
            setLoading={this.setLoading}
            barcodeSpecification={this.barcodeSpecification}
            samples={this.samples}
            fieldDefinitions={fieldDefinitions}
            all_samples_count={all_samples_count}
            all_samples_url={all_samples_url}
            setCalculatedNumSamples={(numSamples) =>
              this.setProgressPerc(numSamples / all_samples_count)
            }
          />
        </div>
        {this.loading && this.progressPerc > 0 && (
          <Stack direction="column" style={{ alignItems: 'center' }}>
            <Typography variant="h3">
              {this.progressPerc === 1
                ? 'Generating PDF'
                : `Generating Labels: ${Math.round(this.progressPerc * 100)}%`}
            </Typography>
            <LinearProgress
              style={{
                position: 'absolute',
                height: 20,
                marginTop: 55,
                width: '100%',
              }}
              value={this.progressPerc * 100}
              variant="determinate"
              color="secondary"
            />
          </Stack>
        )}
      </div>
    );
  }
}
