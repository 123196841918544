import React from 'react';

import { TSearchQuery } from '@/Samples/types';
import { Link } from '@mui/material';
import { Handlers, OmitHandlers } from '../types';
import { EntriesCount, EntriesCountProps } from './EntriesCount';
import {
  EntriesExportLinks,
  EntriesExportLinksProps,
} from './EntriesExportLinks';
import { FilterFieldsProps } from './Filter/FilterFields/FilterFields';
import { FilterSummary } from './Filter/FilterSummary/FilterSummary';
import { SearchFilterProps, SearchFilters } from './SearchFilters';

export type SearchBarProps = {
  exportLinksProps: EntriesExportLinksProps;
  searchFilters: Pick<
    SearchFilterProps,
    | 'availableFilters'
    | 'vaultNameMap'
    | 'user_structure_masked'
    | 'id_list_fd_ids'
  >;
  entriesCount: Omit<EntriesCountProps, 'terminology'>;
  sharedProps: {
    sampleTerminology: string;
    searchQuery: TSearchQuery;
  };
  handlers: OmitHandlers<
    SearchFilterProps,
    'handleFilterPanelClose' | 'handleFilterPanelToggle'
  > &
    Handlers<FilterFieldsProps> & { resetQueryFilters: () => void };
};

// TODO dedupe with eln entries stuff where possible
export class SearchBar extends React.Component<SearchBarProps> {
  state = {
    filterPanelVisible: false,
  };

  handleFilterPanelClose = () => {
    this.setState({ filterPanelVisible: false });
  };

  handleFilterPanelToggle = () => {
    this.setState({ filterPanelVisible: !this.state.filterPanelVisible });
  };

  get handlers() {
    const { handlers } = this.props;
    return {
      ...handlers,
      handleFilterPanelClose: this.handleFilterPanelClose,
      handleFilterPanelToggle: this.handleFilterPanelToggle,
    };
  }

  render() {
    const { filterPanelVisible } = this.state;
    const {
      entriesCount,
      searchFilters,
      sharedProps: { searchQuery, sampleTerminology },
      exportLinksProps,
    } = this.props;
    return (
      <div className="search-bar">
        <SearchFilters
          {...searchFilters}
          searchQuery={searchQuery}
          filterPanelVisible={filterPanelVisible}
          handlers={this.handlers}
        />
        <div className="search-bar__summary">
          <div>
            <FilterSummary
              handleFilterPanelToggle={this.handleFilterPanelToggle}
              queryFilters={searchQuery.query_filters}
            />
            <Link onClick={this.props.handlers.resetQueryFilters}>
              Reset Search Filters
            </Link>
          </div>
          <div className="search-bar__summary_text">
            <EntriesCount {...entriesCount} terminology={sampleTerminology} />
            <EntriesExportLinks {...exportLinksProps} />
          </div>
        </div>
      </div>
    );
  }
}
