import React, { PropsWithChildren } from 'react';

import Icon from '@/shared/components/Icon.jsx';
import { A } from '@/shared/components/sanitizedTags.js';
import { CDD } from '@/typedJS';

export type ExportLinkProps = {
  onClick?: () => void;
  className: string;
  label: string;
  disabled: boolean;
  hidden?: boolean;
  'data-export-path'?: string;
  'data-export-progress-path'?: string;
  // Multiple links can run into issues with overriding the `setElnDisableTo` and only triggering the last one
  // This is a workaround budge to ensure that identity is preserved
  hasNoProgress?: boolean;
};

export default class ExportLink extends React.PureComponent<
  PropsWithChildren<ExportLinkProps>
> {
  state = {
    // eslint-disable-next-line no-jquery/no-jquery-constructor
    inProgress: $('#export_progress_message').length > 0,
  };

  setInProgress = (value: boolean) => {
    this.setState({ inProgress: value });
  };

  componentDidMount() {
    if (!this.props.hasNoProgress) {
      CDD.Export.setElnDisableTo = this.setInProgress;
    }
  }

  render() {
    const {
      onClick,
      className,
      disabled,
      hidden,
      'data-export-path': dataExportPath,
      'data-export-progress-path': dataExportProgressPath,
    } = this.props;

    let fullClassName = className;

    const linkDisabled = disabled || this.state.inProgress;
    if (linkDisabled) {
      fullClassName += ' disabled';
    }

    if (hidden) {
      fullClassName += ' hidden';
    }
    return (
      <A
        href="#"
        className={fullClassName}
        onClick={() => {
          if (!linkDisabled && onClick) {
            onClick();
          }
        }}
        data-export-path={dataExportPath}
        data-export-progress-path={dataExportProgressPath}
      >
        <Icon forceSize="16" icon="pageWhitePut" />
        {this.props.children}
      </A>
    );
  }
}
