import { Molecule } from 'webmolkit/mol/Molecule';
import { MonomerDefinition } from './NaturalMonomers';
import { ForeignMoleculeTemplateDefn, ForeignMoleculeTransient } from 'webmolkit/mol/ForeignMolecule';
import { Graph } from 'webmolkit/mol/Graph';
import { Vec } from 'webmolkit/util/Vec';

export class FragmentInductionError extends Error {
}

export interface PeptideDefn extends ForeignMoleculeTemplateDefn {
  atomN: number; // N-side atom to connect to (will be nitrogen)
  leaveN: number; // N-side atom that leaves (will be hydrogen)
  atomC: number; // C-side atom to connect to (will be carbon)
  leaveC: number; // C-side atom that leaves (will be hydroxyl)
}

export interface PhosphateDefn extends ForeignMoleculeTemplateDefn {
  atomR1: number;
  leaveR1: number;
  atomR2: number;
  leaveR2: number;
}

export interface SugarDefn extends ForeignMoleculeTemplateDefn {
  atomR1: number;
  leaveR1: number;
  atomR2: number;
  leaveR2: number;
  atomR3: number;
  leaveR3: number;
}

export interface BaseDefn extends ForeignMoleculeTemplateDefn {
  atomX: number;
  leaveX: number;
}

export function induceFragmentPeptide(mol: Molecule, name: string, monomer: MonomerDefinition): PeptideDefn {
  const maskN = Vec.booleanArray(false, mol.numAtoms), maskC = Vec.booleanArray(false, mol.numAtoms);
  for (let n = 1; n <= mol.numAtoms; n++) {
    const el = mol.atomElement(n);
    if (mol.atomCharge(n) != 0) continue;
    if (el == 'N') {
      let hasPi = false;
      for (const b of mol.atomAdjBonds(n)) if (mol.bondOrder(b) != 1) hasPi = true;
      if (hasPi) continue;
      maskN[n - 1] = true;
    } else if (el == 'C') {
      let hasOxy = false;
      for (const b of mol.atomAdjBonds(n)) {
        const o = mol.bondOther(b, n);
        if (mol.bondOrder(b) == 2 && mol.atomElement(o) == 'O') hasOxy = true;
      }
      if (!hasOxy) continue;
      maskC[n - 1] = true;
    }
  }

  const maskMain = Vec.booleanArray(true, mol.numAtoms);
  let atomN = 0, leaveN = 0, bondN = 0;
  let atomC = 0, leaveC = 0, bondC = 0;

  for (let n = 1; n <= mol.numAtoms; n++) {
    if (mol.atomicNumber(n) != 0) continue;

    maskMain[n - 1] = false;

    const bonds = mol.atomAdjBonds(n);
    if (bonds.length == 1) {
      const o = mol.bondOther(bonds[0], n);
      if (maskN[o - 1] && atomN == 0) {
        [atomN, leaveN, bondN] = [o, n, bonds[0]];
        mol.setAtomElement(leaveN, 'H');
      }
      if (maskC[o - 1] && atomC == 0) {
        [atomC, leaveC, bondC] = [o, n, bonds[0]];
        mol.setAtomElement(leaveC, 'O');
      }
    }

    // TODO: also see if leaving group is decorated more formally
  }

  const leavingRGroup = (rgname: string): [number, number, number] => {
    for (let n = 1; n <= mol.numAtoms; n++) {
      if (mol.atomElement(n) != rgname || mol.atomAdjCount(n) != 1 || n == leaveN || n == leaveC) continue;
      return [mol.atomAdjList(n)[0], n, mol.atomAdjBonds(n)[0]];
    }
    return [0, 0, 0];
  };

  if (atomN == 0) {
    [atomN, leaveN, bondN] = leavingRGroup('R1');
  }
  if (atomC == 0) {
    [atomC, leaveC, bondC] = leavingRGroup('R2');
  }

  if (atomN == 0 || atomC == 0) {
    throw new FragmentInductionError(`Molfile for ${monomer.code} is missing attachment decorations.`);
  }

  const mainBonds: number[] = [bondN, bondC];
  const attachPoints: (string | number)[] = [atomN, leaveN, 'Al', atomC, leaveC, 'Br'];
  for (let b = 1; b <= mol.numBonds; b++) {
    if (b == bondN || b == bondC) continue;
    const [bfr, bto] = mol.bondFromTo(b);
    if (maskMain[bfr - 1] && !maskMain[bto - 1]) {
      mainBonds.push(b);
      attachPoints.push(...[bfr, bto, 'Cx']);
      mol.setAtomElement(bto, 'H');
    } else if (maskMain[bto - 1] && !maskMain[bfr - 1]) {
      mainBonds.push(b);
      attachPoints.push(...[bto, bfr, 'Cx']);
      mol.setAtomElement(bfr, 'H');
    }
  }

  let attidx = 0;
  const trsMain = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    `${monomer.code}`,
    `bonds=${mainBonds.join(' ')}`,
    'templateClass=AA',
    `natReplace=AA/${monomer.natural}`,
    `attachPoints=${attachPoints.join(' ')}`,
  ].join(',');
  for (let n = 1; n <= mol.numAtoms; n++) if (maskMain[n - 1]) mol.appendAtomTransient(n, trsMain);

  const trsLeaveN = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondN}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveN, trsLeaveN);

  const trsLeaveC = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondC}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveC, trsLeaveC);

  const gph = Graph.fromMolecule(mol);
  for (let n = 1; n <= mol.numBonds; n++) {
    const [bfr, bto] = mol.bondFromTo(n);
    if ((maskMain[bfr - 1] && !maskMain[bto - 1]) || (!maskMain[bfr - 1] && maskMain[bto - 1])) {
      gph.removeEdge(bfr - 1, bto - 1);
    }
  }
  for (const cc of gph.calculateComponentGroups()) {
    if (maskMain[cc[0]]) continue;
    const atoms = Vec.add(cc, 1);
    if (atoms.includes(leaveN) || atoms.includes(leaveC)) continue;
    const bonds: number[] = [];
    for (let b = 1; b <= mol.numBonds; b++) {
      const bfr = mol.bondFrom(b), bto = mol.bondTo(b);
      if (bfr == atomN || bto == atomN || bfr == atomC || bto == atomC) continue;
      const in1 = maskMain[bfr - 1], in2 = maskMain[bto - 1];
      if ((in1 && !in2) || (!in1 && in2)) bonds.push(b);
    }
    const trsLeaveX = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
      ++attidx,
      'X',
      `bonds=${bonds.join(' ')}`,
      'templateClass=LGRP',
    ].join(',');

    if (atoms.length == 1 && mol.atomicNumber(atoms[0]) == 0) {
      mol.setAtomElement(atoms[0], 'H');
    }
    for (const a of atoms) {
      mol.appendAtomTransient(a, trsLeaveX);
    }
  }

  return {
    name,
    natReplace: `AA/${monomer.natural}`,
    mol,
    atomN,
    leaveN,
    atomC,
    leaveC,
  };
}

export function induceFragmentPhosphate(mol: Molecule, name: string, monomer: MonomerDefinition): PhosphateDefn {
  let atomR1 = 0, leaveR1 = 0, bondR1 = 0;
  let atomR2 = 0, leaveR2 = 0, bondR2 = 0;

  for (let n = 1; n <= mol.numAtoms; n++) {
    const el = mol.atomElement(n);
    if (!['R1', 'R2'].includes(el)) continue;
    if (mol.atomAdjCount(n) != 1) throw new FragmentInductionError(`Molfile for ${monomer.code} has an invalid attachment.`);
    const bond = mol.atomAdjBonds(n)[0], atom = mol.bondOther(bond, n);
    if (el == 'R1') {
      [atomR1, leaveR1, bondR1] = [atom, n, bond];
      mol.setAtomElement(leaveR1, 'O');
    } else if (el == 'R2') {
      [atomR2, leaveR2, bondR2] = [atom, n, bond];
      mol.setAtomElement(leaveR2, 'O');
    }
  }

  if (atomR1 == 0 || atomR2 == 0) {
    throw new FragmentInductionError(`Molfile for ${monomer.code} is missing attachment decorations.`);
  }

  const mainBonds: number[] = [bondR1, bondR2];
  const attachPoints: (string | number)[] = [atomR1, leaveR1, 'Al', atomR2, leaveR2, 'Br'];

  let attidx = 0;
  const trsMain = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    `${monomer.code}`,
    `bonds=${mainBonds.join(' ')}`,
    'templateClass=PHOSPHATE',
    `natReplace=PHOSPHATE/${monomer.natural}`,
    `attachPoints=${attachPoints.join(' ')}`,
  ].join(',');
  for (let n = 1; n <= mol.numAtoms; n++) if (n != leaveR1 && n != leaveR2) mol.appendAtomTransient(n, trsMain);

  const trsLeaveR1 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'O',
    `bonds=${bondR1}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveR1, trsLeaveR1);

  const trsLeaveR2 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'O',
    `bonds=${bondR2}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveR2, trsLeaveR2);

  return {
    name,
    natReplace: `PHOSPHATE/${monomer.natural}`,
    mol,
    atomR1,
    leaveR1,
    atomR2,
    leaveR2,
  };
}

export function induceFragmentSugar(mol: Molecule, name: string, monomer: MonomerDefinition): SugarDefn {
  let atomR1 = 0, leaveR1 = 0, bondR1 = 0;
  let atomR2 = 0, leaveR2 = 0, bondR2 = 0;
  let atomR3 = 0, leaveR3 = 0, bondR3 = 0;

  for (let n = 1; n <= mol.numAtoms; n++) {
    const el = mol.atomElement(n);
    if (!['R1', 'R2', 'R3'].includes(el)) continue;
    if (mol.atomAdjCount(n) != 1) throw new FragmentInductionError(`Molfile for ${monomer.code} has an invalid attachment.`);
    const bond = mol.atomAdjBonds(n)[0], atom = mol.bondOther(bond, n);
    if (el == 'R1') {
      [atomR1, leaveR1, bondR1] = [atom, n, bond];
      mol.setAtomElement(leaveR1, 'H');
    } else if (el == 'R2') {
      [atomR2, leaveR2, bondR2] = [atom, n, bond];
      mol.setAtomElement(leaveR2, 'H');
    } else if (el == 'R3') {
      [atomR3, leaveR3, bondR3] = [atom, n, bond];
      mol.setAtomElement(leaveR3, 'H');
    }
  }

  if (atomR1 == 0 || atomR2 == 0 || atomR3 == 0) {
    throw new FragmentInductionError(`Molfile for ${monomer.code} is missing attachment decorations.`);
  }

  const mainBonds: number[] = [bondR1, bondR2];
  const attachPoints: (string | number)[] = [atomR1, leaveR1, 'Al', atomR2, leaveR2, 'Br', atomR3, leaveR3, 'Cx'];

  let attidx = 0;
  const trsMain = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    `${monomer.code}`,
    `bonds=${mainBonds.join(' ')}`,
    'templateClass=SUGAR',
    `natReplace=SUGAR/${monomer.natural}`,
    `attachPoints=${attachPoints.join(' ')}`,
  ].join(',');
  for (let n = 1; n <= mol.numAtoms; n++) if (n != leaveR1 && n != leaveR2 && n != leaveR3) mol.appendAtomTransient(n, trsMain);

  const trsLeaveR1 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondR1}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveR1, trsLeaveR1);

  const trsLeaveR2 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondR2}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveR2, trsLeaveR2);

  const trsLeaveR3 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondR3}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveR3, trsLeaveR3);

  return {
    name,
    natReplace: `SUGAR/${monomer.natural}`,
    mol,
    atomR1,
    leaveR1,
    atomR2,
    leaveR2,
    atomR3,
    leaveR3,
  };
}

export function induceFragmentBase(mol: Molecule, name: string, monomer: MonomerDefinition): BaseDefn {
  let atomX = 0, leaveX = 0, bondX = 0;

  for (let n = 1; n <= mol.numAtoms; n++) {
    if (mol.atomicNumber(n) > 0) continue;
    if (atomX > 0) throw new FragmentInductionError(`Molfile for ${monomer.code} can only have one attachment.`); // may need to upgrade this later
    if (mol.atomAdjCount(n) != 1) throw new FragmentInductionError(`Molfile for ${monomer.code} has an invalid attachment.`);

    const bond = mol.atomAdjBonds(n)[0], atom = mol.bondOther(bond, n);
    [atomX, leaveX, bondX] = [atom, n, bond];
    mol.setAtomElement(leaveX, 'H');
  }

  if (atomX == 0 || atomX == 0) {
    throw new FragmentInductionError(`Molfile for ${monomer.code} is missing attachment decorations.`);
  }

  const mainBonds: number[] = [bondX];
  const attachPoints: (string | number)[] = [atomX, leaveX, 'Al'];

  let attidx = 0;
  const trsMain = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    `${monomer.code}`,
    `bonds=${mainBonds.join(' ')}`,
    'templateClass=BASE',
    `natReplace=BASE/${monomer.natural}`,
    `attachPoints=${attachPoints.join(' ')}`,
  ].join(',');
  for (let n = 1; n <= mol.numAtoms; n++) if (n != leaveX) mol.appendAtomTransient(n, trsMain);

  const trsLeaveR1 = `${ForeignMoleculeTransient.AtomSgroupMultiAttach}:` + [
    ++attidx,
    'H',
    `bonds=${bondX}`,
    'templateClass=LGRP',
  ].join(',');
  mol.appendAtomTransient(leaveX, trsLeaveR1);

  return {
    name,
    natReplace: `BASE/${monomer.natural}`,
    mol,
    atomX,
    leaveX,
  };
}
