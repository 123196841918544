/* eslint-disable no-nested-ternary, multiline-ternary, spaced-comment */

import { Molecule } from 'webmolkit/mol/Molecule';
import { CycleDirection, CycleOrientation, DataOptions, LinearWrap, MacromoleculeType, NucleotideType } from './DataOptions';
import { MonomerDefinition, NATURAL_NUCLEOTIDES, NATURAL_PEPTIDES } from './NaturalMonomers';
import { MDLMOLWriter } from 'webmolkit/io/MDLWriter';
import { ForeignMoleculeTransient } from 'webmolkit/mol/ForeignMolecule';
import { MoleculeStream } from 'webmolkit/io/MoleculeStream';
import { BaseDefn, FragmentInductionError, induceFragmentBase, induceFragmentPeptide, induceFragmentPhosphate, induceFragmentSugar, PeptideDefn, PhosphateDefn, SugarDefn } from './FragmentInduction';

interface SequenceToken {
  code: string;
  monomer: MonomerDefinition;
}

class TokenError extends Error {
}

const OPPOSITE_BASEPAIRS = {
  A: 'T',
  T: 'A',
  U: 'A',
  G: 'C',
  C: 'G',
};

export class MacroBuilder {
  constructor(private dataOptions: DataOptions, private customPeptides: MonomerDefinition[], private customNucleotides: MonomerDefinition[]) {

  }

  // takes a sequence string and converts it into a V3000
  public build(sequence: string): { molfile?: string, error?: string } {
    const { sequenceType } = this.dataOptions;

    let monomers: MonomerDefinition[] = [];
    const withoutBrackets = (code: string): string => code.startsWith('[') && code.endsWith(']') ? code.substring(1, code.length - 1) : code;
    if (sequenceType == MacromoleculeType.PeptideLinear || sequenceType == MacromoleculeType.PeptideCyclic) {
      monomers = [
        ...this.customPeptides.map((mon) => { return { ...mon, code: withoutBrackets(mon.code), match: withoutBrackets(mon.code) }; }),
        ...NATURAL_PEPTIDES.map((mon) => { return { ...mon, match: mon.code }; }),
        ...NATURAL_PEPTIDES.map((mon) => { return { ...mon, match: mon.natural }; }), // single letter versions of natural amino acids
      ];
      // plus 3 letters, then 1 letters
    } else if (sequenceType == MacromoleculeType.Nucleotide) {
      monomers = [...this.customNucleotides, ...NATURAL_NUCLEOTIDES].map((mon) => { return { ...mon, match: mon.code }; });
    }

    try {
      const tokens = this.tokenize(sequence, monomers);

      if (sequenceType == MacromoleculeType.PeptideLinear) {
        return { molfile: this.buildPeptideLinear(tokens) };
      } else if (sequenceType == MacromoleculeType.PeptideCyclic) {
        return { molfile: this.buildPeptideCyclic(tokens) };
      } else if (sequenceType == MacromoleculeType.Nucleotide) {
        return { molfile: this.buildNucleotide(tokens, monomers) };
      }

      return { error: 'Unknown macromolecule type' };
    } catch (ex) {
      if (ex instanceof TokenError) return { error: `Token parsing error: ${ex.message}` };
      if (ex instanceof FragmentInductionError) return { error: `Macromolecule build error: ${ex.message}` };
      throw ex;
    }
  }

  private tokenize(sequence: string, monomers: MonomerDefinition[]): SequenceToken[] {
    const orderedMonomers = monomers.sort((mon1, mon2) => mon2.code.length - mon1.code.length); // longest ones first

    const tokens: SequenceToken[] = [];

    for (let n = 0; n < sequence.length;) {
      const ch = sequence.charAt(n);
      if (ch == '[') {
        const p = sequence.indexOf(']', n + 1);
        if (p < 0) throw new TokenError(`Unmatched bracket at position ${n + 1}`);
        const code = sequence.substring(n + 1, p), brkcode = `[${code}]`;
        const monomer = orderedMonomers.find((look) => look.match == code || look.match == brkcode);
        if (!monomer) throw new TokenError(`Unknown monomer code ${brkcode} at position ${n + 1}`);
        tokens.push({ code: `[${code}]`, monomer });
        n = p + 1;
        continue;
      }

      let hit = false;

      for (const monomer of orderedMonomers) {
        if (sequence.length < n + monomer.match.length) continue;

        const code = sequence.substring(n, n + monomer.match.length);
        const codeComma = `${sequence},`.substring(n, n + monomer.match.length + 1);

        if (codeComma == `${monomer.match},`) {
          tokens.push({ code, monomer });
          n += monomer.match.length + 1;
          hit = true;
          break;
        }
      }
      if (hit) continue;

      for (const monomer of orderedMonomers) {
        if (sequence.length < n + monomer.match.length) continue;

        const code = sequence.substring(n, n + monomer.match.length);

        if (code == monomer.match) {
          tokens.push({ code, monomer });
          n += monomer.match.length;
          hit = true;
          break;
        }
      }
      if (hit) continue;

      throw new TokenError(`Unexpected character ${ch} at position ${n + 1}`);
    }

    return tokens;
  }

  private buildPeptideLinear(tokens: SequenceToken[]): string {
    const wrapping = this.dataOptions.linearWrap;
    const width = this.dataOptions.linearWidth;

    const mol = new Molecule();

    const peptideList: PeptideDefn[] = [];
    for (const tok of tokens) this.findOrCreatePeptide(tok, peptideList);

    mol.keepTransient = true;
    for (let n = 0; n < tokens.length; n++) {
      let x = n * Molecule.IDEALBOND, y = 0;
      if (wrapping == LinearWrap.Winding) {
        const r = Math.floor(n / width);
        const c = r % 2 == 0 ? n % width : width - 1 - (n % width);
        [x, y] = [c * Molecule.IDEALBOND, -r * Molecule.IDEALBOND];
      } else if (wrapping == LinearWrap.Typewriter) {
        const c = n % width, r = Math.floor(n / width);
        [x, y] = [c * Molecule.IDEALBOND, -r * Molecule.IDEALBOND];
      }

      const atom = mol.addAtom(tokens[n].monomer.code, x, y);

      const attachOrder: (string | number)[] = [];
      if (n > 0) {
        const prevAtom = n - 1 + 1;
        attachOrder.push(...[prevAtom, 'Al']);
      }
      if (n < tokens.length - 1) {
        const nextAtom = n + 1 + 1;
        attachOrder.push(...[nextAtom, 'Br']);
      }
      mol.setAtomTransient(atom, [
        `${ForeignMoleculeTransient.AtomSCSRClass}:AA`,
        `${ForeignMoleculeTransient.AtomSCSRSeqID}:${n + 1}`,
        `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
      ]);

      if (n > 0) mol.addBond(atom - 1, atom, 1);
    }

    const mdl = new MDLMOLWriter(mol);
    mdl.scsrTemplates = peptideList;

    return mdl.writeV3000();
  }

  private buildPeptideCyclic(tokens: SequenceToken[]): string {
    const orientation = this.dataOptions.cycleOrientation;
    const direction = this.dataOptions.cycleDirection;

    const mol = new Molecule();

    const peptideList: PeptideDefn[] = [];
    for (const tok of tokens) this.findOrCreatePeptide(tok, peptideList);

    const thetaZero = orientation == CycleOrientation.North ? 0.5 * Math.PI
      : orientation == CycleOrientation.East ? 0
        : orientation == CycleOrientation.South ? -0.5 * Math.PI
          : orientation == CycleOrientation.West ? Math.PI
            : 0;
    const dtheta = (2 * Math.PI / tokens.length);
    const extent = 0.5 * Molecule.IDEALBOND / Math.sin(0.5 * dtheta);
    const dirmod = direction == CycleDirection.Clockwise ? -1 : 1;
    mol.keepTransient = true;
    for (let n = 0, ntokens = tokens.length; n < ntokens; n++) {
      const th = thetaZero + n * dtheta * dirmod;
      const x = Math.cos(th) * extent;
      const y = Math.sin(th) * extent;
      const atom = mol.addAtom(tokens[n].monomer.code, x, y);

      const attachOrder: (string | number)[] = [];
      const prevAtom = (n + ntokens - 1) % ntokens + 1;
      const nextAtom = (n + 1) % ntokens + 1;
      attachOrder.push(...[prevAtom, 'Al']);
      attachOrder.push(...[nextAtom, 'Br']);
      mol.setAtomTransient(atom, [
        `${ForeignMoleculeTransient.AtomSCSRClass}:AA`,
        `${ForeignMoleculeTransient.AtomSCSRSeqID}:${n + 1}`,
        `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
      ]);
    }
    for (let n = 0; n < tokens.length; n++) {
      mol.addBond(n + 1, (n + 1) % tokens.length + 1, 1);
    }

    const mdl = new MDLMOLWriter(mol);
    mdl.scsrTemplates = peptideList;

    return mdl.writeV3000();
  }

  private findOrCreatePeptide(token: SequenceToken, peptideList: PeptideDefn[]): PeptideDefn {
    const { monomer } = token;
    const name = `AA/${monomer.code}/${monomer.code}/`;
    let peptide = peptideList.find((look) => look.name == name);
    if (peptide) return peptide;

    const mol = MoleculeStream.readMDLMOL(token.monomer.molfile);

    peptide = induceFragmentPeptide(mol, name, monomer);
    peptideList.push(peptide);
    return peptide;
  }

  private buildNucleotide(tokens: SequenceToken[], monomers: MonomerDefinition[]): string {
    const isDeoxy = this.dataOptions.nucleotideType == NucleotideType.DNA;
    const isDouble = this.dataOptions.doubleStranded;

    const mol = new Molecule();

    const findMonomer = (name: string) => monomers.find((look) => look.natural == name);

    const phosphateCode = 'P', sugarCode = isDeoxy ? 'dR' : 'R';
    const phosphate = this.createPhosphate(findMonomer(phosphateCode));
    const sugar = this.createSugar(findMonomer(sugarCode));
    const baseList: BaseDefn[] = [];

    const pairTokens: string[] = [];
    for (const tok of tokens) {
      this.findOrCreateBase(tok, baseList);
      if (isDouble) {
        let opposite = OPPOSITE_BASEPAIRS[tok.monomer.natural];
        if (opposite == 'T' && !isDeoxy) opposite = 'U';
        if (opposite) {
          this.findOrCreateBase({ code: opposite, monomer: findMonomer(opposite) }, baseList);
        }
        pairTokens.push(opposite);
      }
    }

    mol.keepTransient = true;

    const sz = tokens.length;
    const atomPhosphate = (idx: number) => idx + 1;
    const atomSugar = (idx: number) => sz + idx + 1;
    const atomBase = (idx: number) => 2 * sz + idx + 1;
    const atomBaseX = (idx: number) => 3 * sz + idx + 1;
    const atomSugarX = (idx: number) => 4 * sz + idx + 1;
    const atomPhosphateX = (idx: number) => 5 * sz + idx + 1;

    for (let n = 0; n < sz; n++) {
      const atom = mol.addAtom(phosphateCode, n * 2 * Molecule.IDEALBOND, 0);
      const attachOrder: (string | number)[] = [];
      if (n > 0) attachOrder.push(...[atomSugar(n - 1), 'Al']);
      attachOrder.push(...[atomSugar(n), 'Br']);
      mol.setAtomTransient(atom, [
        `${ForeignMoleculeTransient.AtomSCSRClass}:PHOSPHATE`,
        `${ForeignMoleculeTransient.AtomSCSRSeqID}:${n + 1}`,
        `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
      ]);
    }

    for (let n = 0; n < sz; n++) {
      const atom = mol.addAtom(sugarCode, (n * 2 + 1) * Molecule.IDEALBOND, 0);
      const attachOrder: (string | number)[] = [];
      attachOrder.push(...[atomPhosphate(n), 'Al']);
      if (n < sz - 1) attachOrder.push(...[atomPhosphate(n + 1), 'Br']);
      attachOrder.push(...[atomBase(n), 'Cx']);
      mol.setAtomTransient(atom, [
        `${ForeignMoleculeTransient.AtomSCSRClass}:SUGAR`,
        `${ForeignMoleculeTransient.AtomSCSRSeqID}:${n + 1}`,
        `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
      ]);
    }

    for (let n = 0; n < sz; n++) {
      const atom = mol.addAtom(tokens[n].monomer.code, (n * 2 + 1) * Molecule.IDEALBOND, -Molecule.IDEALBOND);
      const attachOrder: (string | number)[] = [];
      attachOrder.push(...[atomSugar(n), 'Al']);
      mol.setAtomTransient(atom, [
        `${ForeignMoleculeTransient.AtomSCSRClass}:BASE`,
        `${ForeignMoleculeTransient.AtomSCSRSeqID}:${n + 1}`,
        `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
      ]);
    }

    if (isDouble) {
      for (let n = 0; n < sz; n++) {
        const atom = mol.addAtom(pairTokens[n], (n * 2 + 1) * Molecule.IDEALBOND, -2 * Molecule.IDEALBOND);
        const attachOrder: (string | number)[] = [];
        attachOrder.push(...[atomSugarX(n), 'Al']);
        mol.setAtomTransient(atom, [
          `${ForeignMoleculeTransient.AtomSCSRClass}:BASE`,
          `${ForeignMoleculeTransient.AtomSCSRSeqID}:${sz - n}`,
          `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
        ]);
      }

      for (let n = 0; n < sz; n++) {
        const atom = mol.addAtom(sugarCode, (n * 2 + 1) * Molecule.IDEALBOND, -3 * Molecule.IDEALBOND);
        const attachOrder: (string | number)[] = [];
        if (n > 0) attachOrder.push(...[atomPhosphateX(n - 1), 'Br']);
        attachOrder.push(...[atomPhosphateX(n), 'Al']);
        attachOrder.push(...[atomBaseX(n), 'Cx']);
        mol.setAtomTransient(atom, [
          `${ForeignMoleculeTransient.AtomSCSRClass}:SUGAR`,
          `${ForeignMoleculeTransient.AtomSCSRSeqID}:${sz - n}`,
          `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
        ]);
      }

      for (let n = 0; n < sz; n++) {
        const atom = mol.addAtom(phosphateCode, (n + 1) * 2 * Molecule.IDEALBOND, -3 * Molecule.IDEALBOND);
        const attachOrder: (string | number)[] = [];
        attachOrder.push(...[atomSugarX(n), 'Br']);
        if (n < sz - 1) attachOrder.push(...[atomSugarX(n + 1), 'Al']);
        mol.setAtomTransient(atom, [
          `${ForeignMoleculeTransient.AtomSCSRClass}:PHOSPHATE`,
          `${ForeignMoleculeTransient.AtomSCSRSeqID}:${sz - n}`,
          `${ForeignMoleculeTransient.AtomSCSRAttchOrd}:${attachOrder.join(',')}`,
        ]);
      }
    }

    for (let n = 0; n < sz; n++) {
      if (n > 0) mol.addBond(atomSugar(n - 1), atomPhosphate(n), 1);
      mol.addBond(atomPhosphate(n), atomSugar(n), 1);
      mol.addBond(atomSugar(n), atomBase(n), 1);

      if (isDouble) {
        const hbond = mol.addBond(atomBase(n), atomBaseX(n), 0);
        mol.appendBondTransient(hbond, ForeignMoleculeTransient.BondZeroHydrogen);

        mol.addBond(atomSugarX(n), atomBaseX(n), 1);
        if (n > 0) mol.addBond(atomPhosphateX(n - 1), atomSugarX(n), 1);
        mol.addBond(atomSugarX(n), atomPhosphateX(n), 1);
      }
    }

    const mdl = new MDLMOLWriter(mol);
    mdl.scsrTemplates = [phosphate, sugar, ...baseList];

    return mdl.writeV3000();
  }

  private createPhosphate(monomer: MonomerDefinition): PhosphateDefn {
    const name = `PHOSPHATE/${monomer.code}/${monomer.code}/`;
    const mol = MoleculeStream.readMDLMOL(monomer.molfile);
    return induceFragmentPhosphate(mol, name, monomer);
  }

  private createSugar(monomer: MonomerDefinition): SugarDefn {
    const name = `SUGAR/${monomer.code}/${monomer.code}/`;
    const mol = MoleculeStream.readMDLMOL(monomer.molfile);
    return induceFragmentSugar(mol, name, monomer);
  }

  private findOrCreateBase(token: SequenceToken, baseList: BaseDefn[]): BaseDefn {
    const { monomer } = token;
    const name = `BASE/${monomer.code}/${monomer.code}/`;
    let base = baseList.find((look) => look.name == name);
    if (base) return base;

    const mol = MoleculeStream.readMDLMOL(token.monomer.molfile);

    base = induceFragmentBase(mol, name, monomer);
    baseList.push(base);
    return base;
  }
}
