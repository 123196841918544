import JsBarcode, { type Options as JSBarcodeOptions } from 'jsbarcode';
import QRCode from 'qrcode-svg';
import { type CSSProperties } from 'react';
import { FieldSchema, DocumentProps } from './types';
import { elementLocation, elementSize, SVG_NS } from './drawableUtils';

export interface JSBarcodeProps {
  /**
   * Value to be rendered as barcode
   */
  value: string;
  /**
   * JSBarcode options
   */
  options?: JSBarcodeOptions;
  style?: CSSProperties;
  className?: string;
}

export function createJSBarcode({
  value,
  options,
  barcodeWidth,
  fieldSchema,
  documentProps,
}: JSBarcodeProps & {
  barcodeWidth: number;
  fieldSchema: FieldSchema;
  documentProps: DocumentProps;
}): SVGElement | HTMLElement {
  const { height: elementHeight, width: elementWidth } = elementSize({
    fieldSchema,
    documentProps,
  });
  const { x, y } = elementLocation({
    fieldSchema,
    documentProps,
  });

  const referenceSvg = document.createElementNS(SVG_NS, 'svg');
  referenceSvg.setAttribute('width', elementWidth.toString());
  referenceSvg.setAttribute('height', elementHeight.toString());
  // useless render just to get the number of bars
  // used to grab encodings to resize width, there doesn't seem to be any better way provided by the library other than to render twice
  const barcode = JsBarcode(referenceSvg, value, options);

  // incorrect type returned, we do in fact get the api & the api does have _encodings
  const numBars = (
    barcode as unknown as JsBarcode.api & { _encodings: { data: string }[][] }
  )._encodings[0][0].data.length;
  const barWidth = barcodeWidth / numBars;

  // actual render
  JsBarcode(referenceSvg, value || 'N/A', {
    ...options,
    // width here is the width of one single bar, not the width of the barcode so we have to resize
    width: barWidth,
  });
  const svgGroup = document.createElementNS(SVG_NS, 'g');
  svgGroup.setAttribute(
    'transform',
    `translate(${x - elementWidth / 2}, ${y - elementHeight / 2})`,
  );
  svgGroup.appendChild(referenceSvg);
  return svgGroup;
}
export function createReactQRCode({
  value,
  fieldSchema,
  documentProps,
}: {
  value: string;
  fieldSchema: FieldSchema;
  documentProps: DocumentProps;
}): SVGElement | HTMLElement {
  const { height, width } = elementSize({
    fieldSchema,
    documentProps,
  });
  const { x, y } = elementLocation({
    fieldSchema,
    documentProps,
  });
  // set both to min to make sure QR Code remains square
  const minSize = Math.min(width, height);
  const svg = new QRCode({
    content: (value || 'N/A').toString(),
    width: minSize,
    height: minSize,
    padding: 0,
  }).svg();

  const svgElement = document.createElementNS(SVG_NS, 'svg');
  const svgGroup = document.createElementNS(SVG_NS, 'g');
  // eslint-disable-next-line no-unsafe-innerhtml/no-unsafe-innerhtml
  svgGroup.innerHTML = svg;
  svgGroup.setAttribute(
    'transform',
    `translate(${x - minSize / 2}, ${y - minSize / 2})`,
  );
  svgGroup.appendChild(svgElement);
  return svgGroup;
}
