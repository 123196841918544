/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import { deepClone } from '@/Annotator/data/utils';
import { MacromoleculeType, DataOptions, BuiltResultsCache, CycleOrientation, CycleDirection, DEFAULT_DATA_OPTIONS, LinearWrap, NucleotideType } from './DataOptions';
import { MonomerDefinition } from './NaturalMonomers';
import { MacroBuilder } from './MacroBuilder';
import { SubmitComposition } from './SubmitComposition';
import { MolRenderOptions, RenderMoleculeSVG } from '@cdd/ui-kit/lib/molRendering/v2/RenderMoleculeSVG';
import { Box } from 'webmolkit/util/Geom';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Input, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { CustomMonomerView } from './CustomMonomerView';
import { MonomerAssimilationType } from './MonomerAssimilation';
import { DownloadMoleculeImage, StandardDialogActions } from '@/shared/components';

type Props = {
  dataFileID: number;
  filename: string;
  columnTitles: string[];
  rowData: string[][],
  customPeptides: MonomerDefinition[], // init: copied to state
  customNucleotides: MonomerDefinition[], // ditto
}

type State = {
  customPeptides: MonomerDefinition[],
  customNucleotides: MonomerDefinition[],
  selectedCol: number;
  dataOptions: DataOptions;
  builtResultsCache: BuiltResultsCache[];
  isUploading: boolean;
  textInputWidth: string;
  previewingMolfile: string;
};

const AVAILABLE_TYPES:[MacromoleculeType, string][] = [
  [null, 'Not part of macromolecule'],
  [MacromoleculeType.PeptideLinear, 'Linear peptide sequence'],
  [MacromoleculeType.PeptideCyclic, 'Cyclic peptide sequence'],
  [MacromoleculeType.Nucleotide, 'Nucleotide sequence'],
];

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const PREVIEW_WIDTH = 500;
const PREVIEW_HEIGHT = 500;
const THUMBNAIL_WIDTH = 150;
const THUMBNAIL_HEIGHT = 100;

export class ComposeMacromolecules extends React.Component<Props, State> {
  private watermarkBuild = 0;
  private slurpForm: HTMLFormElement = null;
  private btnContinue: HTMLButtonElement = null;

  constructor(props: Props) {
    super(props);

    const { customPeptides, customNucleotides } = this.props;
    const { selectedCol, dataOptions } = this.guessDefaultSettings(props.columnTitles, props.rowData);

    this.state = {
      customPeptides,
      customNucleotides,
      selectedCol,
      dataOptions,
      builtResultsCache: null,
      isUploading: false,
      textInputWidth: dataOptions.linearWidth.toString(),
      previewingMolfile: null,
    };
  }

  componentDidMount() {
    document.getElementById('composeMacromols_form').style.display = 'none';
    this.slurpForm = document.getElementById('slurpMapping-continue').querySelector('form.slurp-edit-form');
    this.btnContinue = this.slurpForm.button;
    this.btnContinue.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.executeProcess();
    });
  }

  public render(): JSX.Element {
    const { previewingMolfile } = this.state;

    return (
      <div>
        <div key="destination" className="composeMacromol-destination">
          {this.renderDestination()}
        </div>
        <div key="datagrid">
          {this.renderDataGrid()}
        </div>
        {this.state.isUploading && <div className="composeMacromol-isloading">uploading file</div>}

        {previewingMolfile && this.renderPreview()}
      </div>
    );
  }

  private renderDestination(): JSX.Element {
    const { selectedCol, dataOptions } = this.state;
    const { sequenceType, sequenceColumn, linearWrap, cycleOrientation, cycleDirection, nucleotideType, doubleStranded } = dataOptions;

    let checkedType: MacromoleculeType = null;
    if (selectedCol == dataOptions.sequenceColumn) {
      checkedType = sequenceType;
    }

    const describeColumnType = (type: MacromoleculeType, title: string): JSX.Element => {
      const disabled = type != null && selectedCol != sequenceColumn && sequenceType != null;
      const onChange = !disabled ? () => this.handleChangeColumnType(selectedCol, type) : null;

      return (
        <div key={'type' + type}>
          <label className={disabled ? 'disabled' : null}>
            <input type="radio" checked={type === checkedType} disabled={disabled} onChange={onChange}/>
            {title}
          </label>
        </div>
      );
    };

    const radioWrapping = (title: string, optWrapping: LinearWrap): JSX.Element => {
      return (
        <label key={title}>
          <input type="radio" checked={linearWrap == optWrapping} onChange={() => this.handleChangeLinearWrap(optWrapping)}/>
          {title}
        </label>
      );
    };

    const inputWidth = (): JSX.Element => {
      return (
        <Input
          type="text"
          className="composeMacromol-numberinput"
          disabled={linearWrap == LinearWrap.None}
          onChange={(event) => this.handleChangeInputWidth(event.target.value)}
          value={this.state.textInputWidth}
        />
      );
    };

    const radioOrientation = (title: string, optOrientation: CycleOrientation): JSX.Element => {
      return (
        <label key={title}>
          <input type="radio" checked={cycleOrientation == optOrientation} onChange={() => this.handleChangeCycleOrientation(optOrientation)}/>
          {title}
        </label>
      );
    };

    const radioDirection = (title: string, optDirection: CycleDirection): JSX.Element => {
      return (
        <label key={title}>
          <input type="radio" checked={cycleDirection == optDirection} onChange={() => this.handleChangeCycleDirection(optDirection)}/>
          {title}
        </label>
      );
    };

    const radioNucleotide = (title: string, optNucleotideType: NucleotideType): JSX.Element => {
      return (
        <label key={title}>
          <input type="radio" checked={nucleotideType == optNucleotideType} onChange={() => this.handleChangeNucleotideType(optNucleotideType)}/>
          {title}
        </label>
      );
    };

    const radioStrands = (title: string, optDoubleStranded: boolean): JSX.Element => {
      return (
        <label key={title}>
          <input type="radio" checked={doubleStranded == optDoubleStranded} onChange={() => this.handleChangeDoubleStranded(optDoubleStranded)}/>
          {title}
        </label>
      );
    };

    const describeSubType = (): JSX.Element => {
      if (sequenceType == MacromoleculeType.PeptideLinear) {
        return (
          <div key="linear" className="composeMacromol-optiongrid">
            <div key="title1" style={{ gridArea: '1 / title' }}>Wrapping:</div>
            <div key="options1" style={{ gridArea: '1 / content' }}>
              {radioWrapping('Off' /* 'None' */, LinearWrap.None)}
              {/* radioWrapping('Winding', LinearWrap.Winding) */}
              {radioWrapping('On' /* 'Typewriter' */, LinearWrap.Typewriter)}
            </div>
            <div key="title2" style={{ gridArea: '2 / title' }}>Width:</div>
            <div key="options2" style={{ gridArea: '2 / content' }}>
              {inputWidth()}
              units
            </div>
          </div>
        );
      } else if (sequenceType == MacromoleculeType.PeptideCyclic) {
        return (
          <div key="cycle" className="composeMacromol-optiongrid">
            <div key="title1" style={{ gridArea: '1 / title' }}>Orientation:</div>
            <div key="options1" style={{ gridArea: '1 / content' }}>
              {radioOrientation('North', CycleOrientation.North)}
              {radioOrientation('East', CycleOrientation.East)}
              {radioOrientation('South', CycleOrientation.South)}
              {radioOrientation('West', CycleOrientation.West)}
            </div>
            <div key="title2" style={{ gridArea: '2 / title' }}>Direction:</div>
            <div key="options2" style={{ gridArea: '2 / content' }}>
              {radioDirection('Clockwise', CycleDirection.Clockwise)}
              {radioDirection('Anti-clockwise', CycleDirection.Anticlock)}
            </div>
          </div>
        );
      } else if (sequenceType == MacromoleculeType.Nucleotide) {
        return (
          <div key="nucleotide" className="composeMacromol-optiongrid">
            <div key="title1" style={{ gridArea: '1 / title' }}>Backbone:</div>
            <div key="options1" style={{ gridArea: '1 / content' }}>
              {radioNucleotide('RNA', NucleotideType.RNA)}
              {radioNucleotide('DNA', NucleotideType.DNA)}
            </div>
            <div key="title2" style={{ gridArea: '2 / title' }}>Strands:</div>
            <div key="options2" style={{ gridArea: '2 / content' }}>
              {radioStrands('Single', false)}
              {radioStrands('Double', true)}
            </div>
          </div>
        );
      }
      return null;
    };

    let typeHint: MonomerAssimilationType = null;
    if (sequenceType == MacromoleculeType.PeptideLinear || sequenceType == MacromoleculeType.PeptideCyclic) {
      typeHint = MonomerAssimilationType.Peptide;
    } else if (sequenceType == MacromoleculeType.Nucleotide) {
      typeHint = MonomerAssimilationType.Nucleotide;
    }

    return (
      <div className="composeMacromol-optionbox">
        <div key="mainoptions" className="composeMacromol-option-type">
          {AVAILABLE_TYPES.map(([type, title]) => describeColumnType(type, title))}
        </div>
        <div key="suboptions" className="composeMacromol-option-subtype">
          {selectedCol == dataOptions.sequenceColumn ? describeSubType() : null}
        </div>
        <div key="monomers" className="composeMacromol-option-monomers">
          <CustomMonomerView
            customPeptides={this.state.customPeptides}
            customNucleotides={this.state.customNucleotides}
            handleSetMonomers={this.handleSetMonomers}
            typeHint={typeHint}
            />
        </div>
      </div>
    );
  }

  private renderDataGrid(): JSX.Element {
    const { columnTitles, rowData } = this.props;
    const { selectedCol, builtResultsCache } = this.state;

    const ncol = columnTitles.length, nrow = rowData.length;
    const cellList: JSX.Element[] = [];

    let keyCount = 0;
    const key = () => `grid${++keyCount}`;

    for (let n = 0; n < nrow; n++) {
      cellList.push((
        <div key={key()} className="composeMacromol-datacell composeMacromol-gridrowidx" style={{ gridRow: 3 + n, gridColumn: 1 }}>
          {n + 1}
        </div>
      ));
    }

    for (let col = 0; col < ncol; col++) {
      const clsSelect = col == selectedCol ? ' composeMacromol-gridsel' : '';
      const clsIdx = `composeMacromol-datacell composeMacromol-gridcolidx${clsSelect}`;
      const clsTitle = `composeMacromol-datacell composeMacromol-gridtitle${clsSelect}`;
      const clsCell = `composeMacromol-datacell composeMacromol-gridcontent${clsSelect}`;

      let label = ALPHABET.charAt(col % 26);
      const n2 = Math.floor(col / 26);
      if (n2 >= 1 && n2 <= 26) label = ALPHABET.charAt(n2 - 1) + label;
      cellList.push((
        <div key={key()} className={clsIdx} style={{ gridRow: 1, gridColumn: 2 + col }} onClick={() => this.handleSelectColumn(col)}>
          {label}
        </div>
      ));

      cellList.push((
        <div key={key()} className={clsTitle} style={{ gridRow: 2, gridColumn: 2 + col }}>
          {columnTitles[col]}
        </div>
      ));

      for (let row = 0; row < nrow; row++) {
        cellList.push((
          <div key={key()} className={clsCell} style={{ gridRow: 3 + row, gridColumn: 2 + col }}>
            {rowData[row][col]}
          </div>
        ));
      }
    }

    for (let n = 0; n < builtResultsCache?.length; n++) {
      const { thumbnailSVG, previewSVG, molfile, error } = builtResultsCache[n];
      if (thumbnailSVG) {
        const tooltip = (
          <Typography>
            <span style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: previewSVG }}/>
          </Typography>
        );

        const sx: SxProps<Theme> = { maxWidth: `${PREVIEW_WIDTH + 5}px`, maxHeight: `${PREVIEW_HEIGHT + 5}px` };
        cellList.push((
          <Tooltip key={key()} title={tooltip} arrow componentsProps={{ tooltip: { sx } }}>
            <div
              className="composeMacromol-preview"
              style={{ gridRow: 3 + n, gridColumn: 2 + ncol }}
              onClick={() => this.actionOpenMolfile(molfile)}
              >
              <div dangerouslySetInnerHTML={{ __html: thumbnailSVG }}/>
            </div>
          </Tooltip>
        ));
      } else if (error) {
        cellList.push((
          <div key={key()} className="composeMacromol-error" style={{ gridRow: 3 + n, gridColumn: 2 + ncol }}>
            {error}
          </div>
        ));
      }
    }

    return (
      <div className="composeMacromol-datagrid">
        {cellList}
      </div>
    );
  }

  private renderPreview(): JSX.Element {
    const { previewingMolfile } = this.state;

    return (
      <Dialog
        open={true}
        onClose={this.handleClosePreview}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        <DialogTitle className='muiDialog-title'>
          Preview Macromolecule
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          <div className="DownloadMoleculeImage-tabbar">
            <DownloadMoleculeImage
              src={previewingMolfile}
              moleculeName=""
              dataURL={null}
              representationsCalculated={true}
              formatMolfile={previewingMolfile}
            />
          </div>
        </DialogContent>

        <StandardDialogActions className="project__action"
          defaultButtonProps={{
            onClick: this.handleClosePreview,
            label: 'Close',
          }}
        />
      </Dialog>
    );
  }

  private guessDefaultSettings(columnTitles: string[], rowData: string[][]): { selectedCol: number, dataOptions: DataOptions } { // eslint-disable-line @typescript-eslint/no-unused-vars
    let selectedCol = 0;
    const dataOptions: DataOptions = { ...DEFAULT_DATA_OPTIONS };

    // TODO: make this a bit more sophisticated

    for (let n = 0; n < columnTitles.length; n++) {
      const ttl = columnTitles[n];
      if ((ttl == 'Sequence' || ttl == 'Peptide' || ttl == 'Nucleotide') && selectedCol == 0) {
        selectedCol = n;
      }
    }

    return { selectedCol, dataOptions };
  }

  private handleSelectColumn(col: number): void {
    this.setState({ selectedCol: col });
  }

  private handleChangeColumnType(col: number, type: MacromoleculeType): void {
    const dataOptions = deepClone(this.state.dataOptions);
    if (type == null) {
      dataOptions.sequenceColumn = null;
      dataOptions.sequenceType = null;
    } else {
      dataOptions.sequenceColumn = col;
      dataOptions.sequenceType = type;
    }

    let builtResultsCache = this.state.builtResultsCache;
    if (dataOptions.sequenceColumn != this.state.dataOptions.sequenceColumn || dataOptions.sequenceType != this.state.dataOptions.sequenceType) {
      builtResultsCache = null;
    }

    this.setState({ dataOptions, builtResultsCache });
    this.startRebuilding(dataOptions);
  }

  private handleChangeLinearWrap(linearWrap: LinearWrap): void {
    const dataOptions = { ...this.state.dataOptions, linearWrap };
    this.setState({ dataOptions });
    this.startRebuilding(dataOptions);
  }

  private handleChangeInputWidth(textInputWidth: string): void {
    const linearWidth = parseInt(textInputWidth);
    if (linearWidth > 0 && linearWidth != this.state.dataOptions.linearWidth) {
      const dataOptions = { ...this.state.dataOptions, linearWidth };
      this.setState({ dataOptions });
      this.startRebuilding(dataOptions);
    }
    this.setState({ textInputWidth });
  }

  private handleChangeCycleOrientation(cycleOrientation: CycleOrientation): void {
    const dataOptions = { ...this.state.dataOptions, cycleOrientation };
    this.setState({ dataOptions });
    this.startRebuilding(dataOptions);
  }

  private handleChangeCycleDirection(cycleDirection: CycleDirection): void {
    const dataOptions = { ...this.state.dataOptions, cycleDirection };
    this.setState({ dataOptions });
    this.startRebuilding(dataOptions);
  }

  private handleChangeNucleotideType(nucleotideType: NucleotideType): void {
    const dataOptions = { ...this.state.dataOptions, nucleotideType };
    this.setState({ dataOptions });
    this.startRebuilding(dataOptions);
  }

  private handleChangeDoubleStranded(doubleStranded: boolean): void {
    const dataOptions = { ...this.state.dataOptions, doubleStranded };
    this.setState({ dataOptions });
    this.startRebuilding(dataOptions);
  }

  private startRebuilding(dataOptions: DataOptions): void {
    const { rowData } = this.props;
    const { customPeptides, customNucleotides } = this.state;
    if (dataOptions.sequenceColumn == null || dataOptions.sequenceType == null) return;

    const watermark = ++this.watermarkBuild;
    const sequenceList = rowData.map((row) => row[dataOptions.sequenceColumn]);
    const macro = new MacroBuilder(dataOptions, customPeptides, customNucleotides);

    let builtResultsCache: BuiltResultsCache[] = [];

    const buildNextOne = () => {
      if (watermark != this.watermarkBuild) return;

      this.updateProceedButton();

      const idx = builtResultsCache.length;
      if (idx >= sequenceList.length) return;

      const seq = sequenceList[idx];
      const { molfile, error } = macro.build(seq);
      const entry: BuiltResultsCache = { sequence: seq, molfile, error };

      if (molfile) {
        this.generatePreviews(entry);
      }

      builtResultsCache = [...builtResultsCache, entry];
      this.setState({ builtResultsCache });

      setTimeout(buildNextOne, 0);
    };
    setTimeout(buildNextOne, 0);
  }

  private updateProceedButton(): void {
    const { rowData } = this.props;
    const { builtResultsCache } = this.state;

    const isReady = builtResultsCache && rowData.length == builtResultsCache.length && builtResultsCache.every((entry) => !!entry.molfile);

    if (isReady) {
      this.btnContinue.classList.remove('disabled');
    } else {
      this.btnContinue.classList.add('disabled');
    }
  }

  public executeProcess(): void {
    const { columnTitles, rowData } = this.props;
    const { dataOptions, builtResultsCache } = this.state;

    this.btnContinue.classList.add('disabled');

    this.setState({ isUploading: true });

    new SubmitComposition(columnTitles, rowData, dataOptions, builtResultsCache).execute();
  }

  private generatePreviews(entry: BuiltResultsCache): void {
    const options: MolRenderOptions = { isRegistration: true, angstromToPixels: 40 };
    const render = new RenderMoleculeSVG(entry.molfile, null, null, null, options, null);
    render.generate();

    const vg = render.metaVector;
    vg.normalise();
    const downScale = Math.min(PREVIEW_WIDTH / vg.width, PREVIEW_HEIGHT / vg.height);
    if (downScale < 1) vg.transformPrimitives(0, 0, downScale, downScale);
    vg.normalise();
    entry.previewSVG = vg.createSVG();

    vg.transformIntoBox(new Box(0, 0, THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT));
    vg.setSize(THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT);
    entry.thumbnailSVG = vg.createSVG();
  }

  private handleSetMonomers = (customPeptides: MonomerDefinition[], customNucleotides: MonomerDefinition[]): void => {
    this.setState({ customPeptides, customNucleotides });
    setTimeout(() => {
      this.startRebuilding(this.state.dataOptions);
    }, 0);
  };

  private actionOpenMolfile(molfile: string): void {
    this.setState({ previewingMolfile: molfile });
  }

  private handleClosePreview = () => {
    this.setState({ previewingMolfile: null });
  };
}
