import React, { useCallback, useState } from 'react';
import { BioisosterFragment } from './BioisosterResultsTypes';
import { Box, Link, Stack } from '@mui/material';
import { StructureCardView } from '../renderer';
import { InteractiveChemistryImage } from '@/shared/components/ChemistryImage/InteractiveChemistryImage';

export const getSmallestSetOfFragments = (bioisosterFragments: Array<BioisosterFragment>): Array<BioisosterFragment> => {
  const allAtoms = new Set<number>();
  bioisosterFragments.forEach((bioisosterFragment) =>
    bioisosterFragment.atomIdx.forEach((atomIdx) => allAtoms.add(atomIdx)),
  );

  const sortedBySize = [...bioisosterFragments].sort((a, b) => a.size - b.size);
  const smallestSet: Array<BioisosterFragment> = [];
  const found = new Set<number>();
  allAtoms.forEach((atomIdx) => {
    if (found.has(atomIdx)) {
      return;
    }
    const inFragment = sortedBySize.filter((bioisosterFragment) =>
      bioisosterFragment.atomIdx.includes(atomIdx),
    ).sort((a, b) => a.size - b.size);
    const fragment = inFragment[0];
    fragment.atomIdx.forEach((atomIdx) => found.add(atomIdx));
    smallestSet.push(fragment);
  });
  return bioisosterFragments.filter((f) => smallestSet.includes(f));
};

export const getMatchingFragments = (selection: string[], bioisosterFragments: BioisosterFragment[]) => {
  if (!selection.length) {
    return [];
  }

  const selectedAtomIdx: number[][] = [];
  bioisosterFragments.forEach((bioisosterFragment) => {
    if (selection.includes(bioisosterFragment.fragment)) {
      selectedAtomIdx.push(bioisosterFragment.atomIdx);
    }
  });

  const matches = [];
  bioisosterFragments.forEach((bioisosterFragment) => {
    const atomIdx = bioisosterFragment.atomIdx;
    for (const selected of selectedAtomIdx) {
      if (selectedAtomIdx.includes(atomIdx)) {
        matches.push(bioisosterFragment.fragment);
        break;
      }
      if (selected.every((id) => atomIdx.includes(id))) {
        matches.push(bioisosterFragment.fragment);
        break;
      }
    }
  });
  return matches;
};

export const changeSelection = (toggleFragment: string, currentSelection: string[]) => {
  if (currentSelection.includes(toggleFragment)) {
    return currentSelection.filter((fragment) => fragment !== toggleFragment);
  }
  return [...currentSelection, toggleFragment];
};

export const FragmentSelector = (props: {
  fragments: string[];
  structure: string;
  onChange: (fragments: string[]) => void;
  bioisosterFragments: BioisosterFragment[];
  additionalInformation?: JSX.Element | string;
  interactive?: boolean;
}) => {
  const { interactive = false, additionalInformation } = props;
  return (
    <Stack direction='column' sx={{ paddingBottom: '10px', backgroundColor: '#0101', paddingLeft: '10px', paddingTop: '2px' }}>
      {interactive ? <InteractiveFragmentSelector {...props} /> : <StaticFragmentSelector {...props} />}
      <Box mt={1}>{additionalInformation}</Box>
    </Stack>
  );
};

const InteractiveFragmentSelector = (props: {
  fragments: string[];
  structure: string;
  onChange: (fragments: string[]) => void;
  bioisosterFragments: BioisosterFragment[];
}) => {
  const { fragments, structure, onChange, bioisosterFragments } = props;

  const [selection, setSelection] = useState<BioisosterFragment[]>([]);
  const [selectedFragments, setSelectedFragments] = useState<string[]>([]);
  const [highlights, setHighlights] = useState<number[]>([]);

  const handleClick = (atomIdx: number) => {
    const newSelection = bioisosterFragments
      .filter((f) => f.atomIdx.includes(atomIdx - 1));
    setSelection(newSelection);
    setHighlights(newSelection.flatMap((f) => f.atomIdx.map((idx) => idx + 1)));
    const newSelectedFragments = newSelection.map((f) => f.fragment);
    setSelectedFragments(newSelectedFragments);
    onChange(newSelectedFragments);
  };

  const handleSelectionClick = (toggleFragment: string) => {
    const newSelectedFragments = changeSelection(toggleFragment, selectedFragments);
    setSelectedFragments(newSelectedFragments);
    onChange(newSelectedFragments);
  };

  return (
    <Box data-testid='interactive-fragment-selector'>
      <Box>
        Click on structure to select fragments
      </Box>
      <Stack direction='row' spacing='5px' alignContent='center'>

        <Box sx={{ backgroundColor: 'white', padding: '5px', margin: '3px' }}>
          <InteractiveChemistryImage
            src={structure}
            width={200}
            height={200}
            onClick={handleClick}
            highlightedAtomNumbers={highlights} />
        </Box>
        <Stack direction='row' spacing='2px'>
          {selection.map((bioisosterFragment) => {
            return (
              <StructureCardView
                key={bioisosterFragment.fragment}
                structure={structure}
                structureSize={100}
                height={100}
                selected={fragments.includes(bioisosterFragment.fragment)}
                onClick={() => handleSelectionClick(bioisosterFragment.fragment)}
                dataTestid={'bioisoster-fragment-selector'}
                skipHighlightAlignment={true}
                highlightedAtomNumbers={bioisosterFragment.atomIdx.map((atomIdx) => atomIdx + 1)}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export const StaticFragmentSelector = (props: {
  fragments: Array<string>;
  structure: string;
  onChange: (fragments: Array<string>) => void;
  bioisosterFragments: Array<BioisosterFragment>;
}) => {
  const { fragments, structure, onChange, bioisosterFragments } = props;

  const shownFragments = getSmallestSetOfFragments(bioisosterFragments);
  const [selection, setSelection] = useState<string[]>(shownFragments.map((f) => f.fragment));

  const handleClick = useCallback((toggleFragment: string) => {
    updateSelection(changeSelection(toggleFragment, selection));
  }, [selection]);

  const selectAll = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    updateSelection(shownFragments.map((bioisosterFragment) => bioisosterFragment.fragment));
  }, [onChange, shownFragments]);

  const selectNone = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    updateSelection([]);
  }, [onChange]);

  const updateSelection = (newSelection: string[]) => {
    setSelection(newSelection);
    onChange(getMatchingFragments(newSelection, bioisosterFragments));
  };

  return (
    <Box data-testid='static-fragment-selector'>
      <Box>
        Filter by fragment: <Link href='#' onClick={selectAll}>All</Link> / <Link href='#' onClick={selectNone}>None</Link>
      </Box>
      <Stack direction='row' spacing='2px'>
        {shownFragments.map((bioisosterFragment) => {
          const fragment = bioisosterFragment.fragment;
          return (
            <StructureCardView
              key={fragment}
              structure={structure}
              structureSize={100}
              selected={selection.includes(fragment)}
              onClick={() => handleClick(fragment)}
              dataTestid={'bioisoster-fragment-selector'}
              skipHighlightAlignment={true}
              highlightedAtomNumbers={bioisosterFragment.atomIdx.map((atomIdx) => atomIdx + 1)}
            />
          );
        })}
      </Stack>
      {!fragments.length && <Box style={{ color: 'red' }}>Please select a fragment to view suggestions</Box>}
    </Box>
  );
};
