/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';

import axios from 'axios';
import { CDD, activeAjax } from '@/typedJS';
import { TabContext } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { ParsingType, ParsingSection, ParsingArea, TabbedCells, ParserUtil, ParsingFixedValue, ParsingTabularData, ParsingPlateBlock, SavedTemplate, SavedTemplateMatch } from './parserUtil';
import { TableDataComposer } from './TableDataComposer';
import { TemplateLoader } from './TemplateLoader';
import { TemplateSaver } from './TemplateSaver';
import { SectionCommandBar } from './SectionCommandBar';
import { CondensedTheme } from '@/shared/components/MuiTheme';
import { deepClone } from '@/Annotator/data/utils';
import { ParserImpliedStampBlocks } from './ParserImpliedStampBlocks';

declare let Pollers: any; // eslint-disable-line @typescript-eslint/no-explicit-any

type Props = {
  dataFileID: number;
  filename: string;
  parserSelectedName: string,
  savedTemplates: SavedTemplate[],
  tabNames: string[];
  sheets: TabbedCells[];
}

type State = {
  selectedTab: number;
  parsingSections: ParsingSection[];
  undoStack: ParsingSection[][];
  redoStack: ParsingSection[][];
  sectionClosed: boolean[];
  displayedRows: number;
  initialGridHeight: number;
  selectedArea: ParsingArea;
  highlightedSectionLeft: number;
  highlightedSectionRight: number;
  selectedTemplateName: string;
  requestForDelete: SavedTemplate;
  deletedTemplates: string[];
  isUploading: boolean;
  matchMessage: string;
};

const TAB_PREVIEW = -1;
const MIN_ROW_DISPLAY = 200;
const INCR_ROW_SCROLL = 50;
const SCROLL_THRESHOLD = 100;

export class ImportParser extends React.Component<Props, State> {
  private matchedTemplates: SavedTemplateMatch[] = [];
  private immediateProceed = false;
  private alreadyProceeded = false;
  private scrollParentRef: HTMLDivElement = null;
  private scrollElementRef: HTMLDivElement = null;
  private scrollChildRef: HTMLDivElement = null;
  private previewLength = 0;

  constructor(props: Props) {
    super(props);

    const PARSER_MISMATCH_MESSAGE = 'Selected parser does not match the file. Please select a different parser or create a new one using the assign tools.';

    const { parserSelectedName, savedTemplates, tabNames, sheets } = props;
    this.matchedTemplates = savedTemplates.map((template) => ParserUtil.matchTemplateToSheets(template, tabNames, sheets));
    let matchMessage: string = null;
    if (parserSelectedName) {
      this.matchedTemplates = this.matchedTemplates.filter((template) => template.name == parserSelectedName);
      if (this.matchedTemplates.length == 0) {
        matchMessage = PARSER_MISMATCH_MESSAGE;
      } else {
        matchMessage = 'Parsing the file and preparing it for mapping.';
        this.immediateProceed = true;
      }
    }
    this.matchedTemplates.sort((t1, t2) => (t2.score ?? 0) - (t1.score ?? 0));

    let bestMatched: SavedTemplateMatch = null;
    if (!this.immediateProceed) {
      bestMatched = this.matchedTemplates.length > 0 && this.matchedTemplates[0].score == 1 ? this.matchedTemplates[0] : null;
    } else {
      bestMatched = this.matchedTemplates.length > 0 && this.matchedTemplates[0].score > 0 ? this.matchedTemplates[0] : null;
      if (!bestMatched) {
        this.immediateProceed = false;
        matchMessage = PARSER_MISMATCH_MESSAGE;
      }
    }

    const parsingSections = bestMatched ? ParserUtil.sanitizeSections(props.sheets, bestMatched.template_json.parsingSections)
      : ParserUtil.guessInitialSections(props.tabNames, props.sheets);

    this.state = {
      parsingSections,
      undoStack: [],
      redoStack: [],
      sectionClosed: new Array(parsingSections.length).fill(false),
      selectedTab: this.immediateProceed ? TAB_PREVIEW : 0,
      displayedRows: MIN_ROW_DISPLAY,
      initialGridHeight: 0,
      selectedArea: null,
      highlightedSectionLeft: -1,
      highlightedSectionRight: -1,
      selectedTemplateName: bestMatched?.name,
      requestForDelete: null,
      deletedTemplates: [],
      isUploading: false,
      matchMessage,
    };
  }

  componentDidMount() {
    if (this.immediateProceed && !this.alreadyProceeded && !this.state.isUploading) {
      this.alreadyProceeded = true;
      setTimeout(this.handleProceed, 1);
    }
  }

  public render(): JSX.Element {
    const { tabNames, sheets } = this.props;
    const { selectedTab, parsingSections, selectedTemplateName, requestForDelete, deletedTemplates, matchMessage, isUploading } = this.state;

    const savedTemplates = this.props.savedTemplates.filter((template) => !deletedTemplates.includes(template.name));

    const tabKey = (idx: number) => `tab${idx}`;

    const tabButtons: JSX.Element[] = [];
    for (let n = 0; n < tabNames.length; n++) {
      tabButtons.push((
        <Tab
          key={tabKey(n)}
          label={tabNames[n]}
          value={tabKey(n)}
          disabled={isUploading}
        />
      ));
    }

    return (
      <>
        {matchMessage && (<div className="PlateBlockImporter-matchmessage">{matchMessage}</div>)}
        <div className="PlateBlockImporter-twosides">
          <div key="left">
            {savedTemplates.length > 0 && (
              <TemplateLoader
                matchedTemplates={this.matchedTemplates}
                selectedTemplateName={selectedTemplateName}
                tabNames={tabNames}
                sheets={sheets}
                isUploading={isUploading}
                handleApplyMatchedTemplate={this.handleApplyMatchedTemplate}
                handleDeleteTemplate={this.handleDeleteTemplate}
              />
            )}
          </div>
          <div key="right">
            <TemplateSaver
              savedTemplates={savedTemplates}
              selectedTemplateName={selectedTemplateName}
              tabNames={tabNames}
              parsingSections={parsingSections}
              undoStack={this.state.undoStack}
              redoStack={this.state.redoStack}
              handleClear={this.handleClear}
              handleUndo={this.handleUndo}
              handleRedo={this.handleRedo}
            />
          </div>
        </div>

        <TabContext value={tabKey(selectedTab)}>
          <div className="PlateBlockImporter-tabbar">
            <Tabs
              value={tabKey(selectedTab)}
              onChange={this.handleChangeTab}
              className="PlateBlockImporter-fullwidth"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              >
              {tabButtons}
              <Tab
                key={tabKey(TAB_PREVIEW)}
                className="PlateBlockImporter-preview"
                label="Preview Import Table"
                value={tabKey(TAB_PREVIEW)}
                disabled={isUploading}
              />
            </Tabs>
          </div>
          {selectedTab == TAB_PREVIEW ? this.buildPreview() : this.buildTab(selectedTab)}
        </TabContext>

        <Dialog open={requestForDelete != null} maxWidth="sm" fullWidth>
          <DialogTitle>Confirm the action</DialogTitle>
          <DialogContent>
            <Typography>Delete parser <b>{requestForDelete?.name}</b>?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteTemplateCancel}>
              Cancel
            </Button>
            <Button onClick={this.handleDeleteTemplateConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  private buildTab(tabidx: number): JSX.Element {
    const { sheets } = this.props;
    const { parsingSections, displayedRows, initialGridHeight, selectedArea, highlightedSectionLeft, highlightedSectionRight } = this.state;
    const sheet = sheets[tabidx];

    const { ncols } = ParserUtil.ascertainGridSize(sheet);

    const tableRows: JSX.Element[] = [];

    const SEL_BORDER = '1px solid black';

    const examineArea = (x: number, y: number, area: ParsingArea, style: React.CSSProperties): boolean => {
      if (!area) {
        // if (isSelection) style.cursor = 'pointer';
        return false;
      }

      const areaX1 = area.x, areaX2 = areaX1 + area.w - 1;
      const areaY1 = area.y, areaY2 = areaY1 + area.h - 1;

      let inArea = false;

      if (x >= areaX1 && x <= areaX2 && y >= areaY1 && y <= areaY2) {
        // if (isSelection) style.cursor = 'no-drop';
        if (x == areaX2) style.borderRight = SEL_BORDER;
        if (y == areaY2) style.borderBottom = SEL_BORDER;
        inArea = true;
      } else {
        // if (isSelection) style.cursor = 'cell';
      }

      if (y >= areaY1 && y <= areaY2) {
        if (x == 0 && areaX1 == 0) style.borderLeft = SEL_BORDER;
        else if (x == areaX1 - 1) style.borderRight = SEL_BORDER;
      }
      if (x >= areaX1 && x <= areaX2) {
        if (y == 0 && areaY1 == 0) style.borderTop = SEL_BORDER;
        else if (y == areaY1 - 1) style.borderBottom = SEL_BORDER;
      }

      return inArea;
    };

    const theseSections: number[] = [];
    for (let n = 0; n < parsingSections.length; n++) {
      if (parsingSections[n].tabIndex == tabidx) theseSections.push(n);
    }
    const stamped = new ParserImpliedStampBlocks(parsingSections, sheets);
    stamped.perceive();
    const impliedSections = stamped.impliedBlocks.filter((section) => section.tabIndex == tabidx);

    for (let y = 0; y < sheet.length && y < displayedRows; y++) {
      const cellList: JSX.Element[] = [];

      for (let x = 0; x < ncols; x++) {
        const str = sheet[y][x] ?? '';
        let className = 'PlateBlockImporter-tablecell', extraClass: string = null;
        const style: React.CSSProperties = {};

        let selectedIndex = -1;
        for (const idx of theseSections) {
          const section = parsingSections[idx];
          const sfxHigh = idx == highlightedSectionRight ? '-high' : '';

          if (section.type == ParsingType.FixedValue) {
            const fixedValue = section as ParsingFixedValue;
            if (examineArea(x, y, fixedValue.areaValue, style)) {
              extraClass = 'PlateBlockImporter-tablecell-content' + sfxHigh;
              selectedIndex = idx;
            }
          } else if (section.type == ParsingType.TabularData) {
            const tabularData = section as ParsingTabularData;
            if (examineArea(x, y, tabularData.areaHeader, style)) {
              extraClass = 'PlateBlockImporter-tablecell-header' + sfxHigh;
              selectedIndex = idx;
            } else if (examineArea(x, y, tabularData.areaContent, style)) {
              extraClass = 'PlateBlockImporter-tablecell-content' + sfxHigh;
              selectedIndex = idx;
            } else if ((tabularData.fixedValues ?? []).some((fval) => examineArea(x, y, fval.areaValue, style))) {
              extraClass = 'PlateBlockImporter-tablecell-header' + sfxHigh;
              selectedIndex = idx;
            }
          } if (section.type == ParsingType.PlateBlock) {
            const plateBlock = section as ParsingPlateBlock;
            if (examineArea(x, y, plateBlock.areaColumns, style)) {
              extraClass = 'PlateBlockImporter-tablecell-header' + sfxHigh;
              selectedIndex = idx;
            } else if (examineArea(x, y, plateBlock.areaRows, style)) {
              extraClass = 'PlateBlockImporter-tablecell-header' + sfxHigh;
              selectedIndex = idx;
            } else if (examineArea(x, y, plateBlock.areaBlock, style)) {
              extraClass = 'PlateBlockImporter-tablecell-content' + sfxHigh;
              selectedIndex = idx;
            } else if ((plateBlock.fixedValues ?? []).some((fval) => examineArea(x, y, fval.areaValue, style))) {
              extraClass = 'PlateBlockImporter-tablecell-header' + sfxHigh;
              selectedIndex = idx;
            }
          }
        }
        for (const plateBlock of impliedSections) {
          if (examineArea(x, y, plateBlock.areaColumns, style)) {
            extraClass = 'PlateBlockImporter-tablecell-header-ghost';
          } else if (examineArea(x, y, plateBlock.areaRows, style)) {
            extraClass = 'PlateBlockImporter-tablecell-header-ghost';
          } else if (examineArea(x, y, plateBlock.areaBlock, style)) {
            extraClass = 'PlateBlockImporter-tablecell-content-ghost';
          } else if ((plateBlock.fixedValues ?? []).some((fval) => examineArea(x, y, fval.areaValue, style))) {
            extraClass = 'PlateBlockImporter-tablecell-header-ghost';
          }
        }

        // note: having cursor types per cell blags performance quite a lot: no idea why
        if (!selectedArea) {
          // className += ' PlateBlockImporter-tablecell-pointer';
        } else if (examineArea(x, y, selectedArea, style)) {
          // className += ' PlateBlockImporter-tablecell-inside';
          extraClass = 'PlateBlockImporter-tablecell-selected';
        } else {
          // className += ' PlateBlockImporter-tablecell-outside';
        }

        if (extraClass) className += ' ' + extraClass;

        const mouseEnter = selectedIndex >= 0 ? () => this.setState({ highlightedSectionLeft: selectedIndex }) : null;
        const mouseLeave = selectedIndex >= 0 ? () => this.setState({ highlightedSectionLeft: -1 }) : null;

        cellList.push((
          <td
            key={`row${y}-cell${x}`}
            className={className}
            style={style}
            data-cell={`${x}-${y}`}
            onClick={this.handleClickCell}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            {str || '\u{00A0}'}
          </td>
        ));
      }

      tableRows.push((<tr key={`row${y}`}>{cellList}</tr>));
    }

    const updateSections = (parsingSections, clearSelection) => {
      this.setSections(parsingSections);
      if (clearSelection) {
        this.setState({ selectedArea: null });
      }
    };

    const changeHighlight = (idx: number) => {
      this.setState({ highlightedSectionRight: idx });
    };

    const changeClosed = (sectionClosed: boolean[]) => {
      this.setState({ sectionClosed });
    };

    const mainGridStyle: React.CSSProperties = initialGridHeight > 0 ? { height: `${initialGridHeight}px` } : {};

    return (
      <CondensedTheme>
        <div
          className="PlateBlockImporter-maingrid"
          ref={this.handleInitialGridSizing}
        >
          <div
            key="left"
            className="PlateBlockImporter-gridleft"
            style={mainGridStyle}
            onScroll={this.handleScroll}
            onMouseEnter={() => this.setState({ highlightedSectionRight: -1 })}
            ref={(element) => this.scrollElementRef = element}
          >
            <table
              className="PlateBlockImporter-tablegrid"
              ref={(element) => this.scrollChildRef = element}
            >
              <tbody>
                {tableRows}
              </tbody>
            </table>
          </div>
          <div key="right" className="PlateBlockImporter-gridright">
            <SectionCommandBar
              sheets={this.props.sheets}
              parsingSections={this.state.parsingSections}
              sectionClosed={this.state.sectionClosed}
              selectedTab={this.state.selectedTab}
              selectedArea={this.state.selectedArea}
              highlightedSectionLeft={highlightedSectionLeft}
              highlightedSectionRight={highlightedSectionRight}
              callbackUpdateParsingSections={updateSections}
              callbackChangeHighlight={changeHighlight}
              callbackChangeClosed={changeClosed}
            />
          </div>
        </div>
      </CondensedTheme>
    );
  }

  private buildPreview(): JSX.Element {
    const { isUploading, displayedRows, initialGridHeight } = this.state;

    const composer = new TableDataComposer(this.props.tabNames, this.props.sheets, this.state.parsingSections);
    composer.generate();
    const matrix = composer.buildMatrix();
    this.previewLength = matrix.length;
    if (displayedRows < matrix.length) {
      matrix.splice(displayedRows);
    }

    const proceedDisabled = composer.problems.length > 0 || this.state.parsingSections.length == 0;
    const tooltipProceed = proceedDisabled && (
      <Typography>
        Create at least one valid assignment to proceed.
      </Typography>
    );

    const mainGridStyle: React.CSSProperties = initialGridHeight > 0 ? { height: `${initialGridHeight}px` } : {};

    const needScrollEnd = this.state.displayedRows < this.previewLength;

    return (
      <>
        {!isUploading ? (
          <div className="PlateBlockImporter-buttons-proceed">
            <Tooltip
              key="tip-proceed"
              title={tooltipProceed}
              arrow
              placement="left"
            >
              <div className="PlateBlockImporter-button-inline">
                <Button onClick={this.handleProceed} variant="contained" disabled={proceedDisabled}>
                  Proceed
                </Button>
              </div>
            </Tooltip>
          </div>
        ) : !this.immediateProceed ? (
          <div className="PlateBlockImporter-processing">
            Processing the reformulated datafile in tabular format and uploading it for the next step.
          </div>
        ) : <p />}

        {composer.problems.map((problem, idx) => {
          if (problem.tabIndex == null) return null;
          return (
            <p key={`problem${idx}`} className="PlateBlockImporter-problem">Problem occurred: {problem.message}</p>
          );
        })}

        <div
          className="PlateBlockImporter-maingrid"
          style={mainGridStyle}
          ref={this.handleInitialGridSizing}
        >
          <div
            key="left"
            className="PlateBlockImporter-gridleft"
            onScroll={this.handleScroll}
            ref={(element) => this.scrollElementRef = element}
          >
            <table
              className="PlateBlockImporter-tablegrid"
              ref={(element) => this.scrollChildRef = element}
            >
              <tbody>
                {matrix.map((row, rowidx) => {
                  const cellList = row.map((value, colidx) => {
                    const className = rowidx == 0 ? 'PlateBlockImporter-previewheader' : 'PlateBlockImporter-previewcell';
                    return (<td key={`col${colidx}`} className={className}>{value || '\u{00A0}'}</td>);
                  });
                  if (rowidx == 0) {
                    cellList.unshift((<td key="idx" />));
                  } else {
                    cellList.unshift((<td key="idx" className="PlateBlockImporter-previewrowidx">{rowidx}</td>));
                  }

                  return (
                    <tr key={`row${rowidx}`}>{cellList}</tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {needScrollEnd && (
            <div className="PlateBlockImporter-absbottomright">
              <Button onClick={this.handleJumpToEnd}>
                Jump to End
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }

  private setSections(parsingSections: ParsingSection[]): void {
    const undoStack = [...this.state.undoStack, deepClone(this.state.parsingSections)];
    if (undoStack.length > 20) undoStack.splice(0, undoStack.length - 20);
    this.setState({ parsingSections, undoStack, redoStack: [] });
  }

  private handleChangeTab = (_event: React.ChangeEvent, val: string): void => {
    if (this.scrollElementRef) {
      this.scrollElementRef.scrollTop = 0;
    }
    this.setState({
      selectedTab: parseInt(val.substring(3)),
      selectedArea: null,
      matchMessage: null,
      displayedRows: MIN_ROW_DISPLAY,
    });
  };

  private handleClickCell = (event: React.MouseEvent): void => {
    const dataCell = event.currentTarget.getAttribute('data-cell').split('-');
    const x = parseInt(dataCell[0]), y = parseInt(dataCell[1]);

    const { selectedArea } = this.state;
    if (!selectedArea) {
      this.setState({ selectedArea: { x, y, w: 1, h: 1 } });
      return;
    }

    let selX1 = selectedArea.x, selX2 = selX1 + selectedArea.w - 1;
    let selY1 = selectedArea.y, selY2 = selY1 + selectedArea.h - 1;
    if (x >= selX1 && x <= selX2 && y >= selY1 && y <= selY2) {
      this.setState({ selectedArea: null });
      return;
    }

    selX1 = Math.min(selX1, x);
    selX2 = Math.max(selX2, x);
    selY1 = Math.min(selY1, y);
    selY2 = Math.max(selY2, y);
    this.setState({ selectedArea: { x: selX1, y: selY1, w: selX2 - selX1 + 1, h: selY2 - selY1 + 1 } });
  };

  private handleClear = (): void => {
    const { parsingSections } = this.state;
    if (parsingSections.length == 0) return;
    this.setSections([]);
    this.setState({
      selectedTemplateName: null,
      matchMessage: null,
    });
  };

  private handleUndo = (): void => {
    const { parsingSections, undoStack, redoStack } = this.state;
    if (undoStack.length == 0) return;
    this.setState({
      undoStack: undoStack.slice(0, undoStack.length - 1),
      parsingSections: deepClone(undoStack[undoStack.length - 1]),
      redoStack: [...redoStack, deepClone(parsingSections)],
    });
  };

  private handleRedo = (): void => {
    const { parsingSections, undoStack, redoStack } = this.state;
    if (redoStack.length == 0) return;
    this.setState({
      undoStack: [...undoStack, deepClone(parsingSections)],
      parsingSections: deepClone(redoStack[redoStack.length - 1]),
      redoStack: redoStack.slice(0, redoStack.length - 1),
    });
  };

  private handleApplyMatchedTemplate = (template: SavedTemplate): void => {
    if (template) {
      this.setSections(template.template_json.parsingSections);
      this.setState({
        selectedTemplateName: template.name,
        matchMessage: null,
      });
    } else {
      const { tabNames, sheets } = this.props;
      this.setSections(ParserUtil.guessInitialSections(tabNames, sheets));
      this.setState({
        selectedTemplateName: null,
        matchMessage: null,
      });
    }
  };

  private handleDeleteTemplate = (template: SavedTemplate): void => {
    this.setState({ requestForDelete: template });
  };

  private handleDeleteTemplateCancel = (): void => {
    this.setState({ requestForDelete: null });
  };

  private handleDeleteTemplateConfirm = (): void => {
    const { requestForDelete } = this.state;
    if (!requestForDelete.id) return;
    const url = `/${CDD.ActiveDataContext.toContextParam}/plate_block_templates/${requestForDelete.id}.json`;

    (async () => {
      const response = await axios.delete(url);
      if (response.status >= 200 && response.status < 300) {
        const deletedTemplates = [...this.state.deletedTemplates, requestForDelete.name];
        this.matchedTemplates = this.matchedTemplates.filter((template) => !deletedTemplates.includes(template.name));
        this.setState({ deletedTemplates });
      } else {
        console.error('Deletion of parser failed.');
      }
      this.setState({ requestForDelete: null });
    })();
  };

  private handleProceed = (): void => {
    const composer = new TableDataComposer(this.props.tabNames, this.props.sheets, this.state.parsingSections);
    composer.generate();
    const csv = composer.buildTextFile();

    const form = document.getElementById('plateBlock_form') as HTMLFormElement;
    const form_data = new FormData(form);
    form_data.set('data_file[file_data]', csv);
    const url = new URL(form.action);
    url.searchParams.set('format', 'json');
    this.setState({ isUploading: true });

    activeAjax.start();

    (async () => {
      try {
        const response = await axios<unknown, {
          id: number,
          name: string
        }>({
          'url': url.toString(),
          'method': 'post',
          'data': form_data,
          'headers': { 'Content-Type': 'application/json' },
        });
        const dataFile = response.data;

        const browserURL = new URL(window.location.href);
        const vaultID = browserURL.pathname.split('/')[2];
        const newSlurpURL = `${url.origin}/vaults/${vaultID}/data_files/${dataFile.id}/slurps/new`;

        const slurpReady = (slurp: { url: string }): void => {
          Pollers.stop('dataFileWatcher');
          activeAjax.stop();
          window.location.href = slurp.url + '/mapping/edit';
        };

        Pollers.start('dataFileWatcher', newSlurpURL, 2, slurpReady);
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error);
        this.setState({ isUploading: false });
      }
    })();
  };

  private handleInitialGridSizing = (element: HTMLDivElement) => {
    if (!element) return;
    this.scrollParentRef = element;
    const bounds = element.getBoundingClientRect();
    const top = bounds.y + window.scrollY;
    const h = Math.floor(window.innerHeight - top);
    if (h == this.state.initialGridHeight) return;
    this.setState({ initialGridHeight: h });
  };

  private handleScroll = () => {
    if (!this.scrollParentRef) return;

    const { sheets } = this.props;
    const { selectedTab, displayedRows } = this.state;
    const maxRows = selectedTab == TAB_PREVIEW ? this.previewLength : sheets[selectedTab].length;
    if (displayedRows >= maxRows) return;

    const topY = this.scrollElementRef.scrollTop;
    const contentH = this.scrollChildRef.clientHeight;
    const viewH = this.scrollParentRef.clientHeight;
    const approach = contentH - topY - viewH;

    if (approach < SCROLL_THRESHOLD) {
      setTimeout(() => this.setState({ displayedRows: Math.min(maxRows, displayedRows + INCR_ROW_SCROLL) }));
    }
  };

  private handleJumpToEnd = () => {
    if (!this.scrollParentRef) return;

    const { sheets } = this.props;
    const { selectedTab, displayedRows } = this.state;
    const maxRows = selectedTab == TAB_PREVIEW ? this.previewLength : sheets[selectedTab].length;
    if (displayedRows >= maxRows) return;

    this.setState({ displayedRows: maxRows });

    setTimeout(() => {
      const contentH = this.scrollChildRef.clientHeight;
      const viewH = this.scrollParentRef.clientHeight;
      this.scrollElementRef.scrollTop = contentH - viewH;
    }, 1);
  };
}
